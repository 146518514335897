<script setup lang="ts">
import { watch } from 'vue'
import { i18nRoute, locale, findDefaultLocale } from '@/i18n'
import { useRoute, RouterView, useRouter } from 'vue-router'
import { useUserStore, useGlobalStore } from '@/stores'
import { getUser, getFutureAndLiveMatches, subscribeUser, subscribeMatches, notify, NotifyType, NotifyCategory } from '@/gql/requests'
import { giveAnalyticInfo } from '@/gql/user'
import { useQuery, useSubscription, useLazyQuery } from '@vue/apollo-composable'
import dayjs from 'dayjs'
import { startConnection } from '@/composables/socket-io'
import * as amplitude from '@/composables/amplitude'
import { setCookie } from '@/composables/cookie'
import { useGtm } from '@gtm-support/vue-gtm'
import { modalComponent, modals } from '@/components/UI/Modal'
import { useCreatePayment } from '@/composables/payment'
import { selectedIndexDay, insiderOneDays } from '@/composables/tariffs'

const route = useRoute()
const router = useRouter()
const gtm = useGtm()
amplitude.init()
const userStore = useUserStore()
const globalStore = useGlobalStore()
const createPayment = useCreatePayment()
window.addEventListener('vite:preloadError', (event) => {
   event.preventDefault()
   event.stopPropagation()
   window.location.reload()
   return false
})

const query = new URLSearchParams(window.location.search)
const queryAuth = query.get('auth')

if (query.get('action') === 'signup') {
   if (query.get('provider') === 'google' && gtm) {
      amplitude.track('response.gmail', 'signup-page', true)
      gtm.trackEvent({
         event: 'signup',
         method: 'gmail '
      })
   }
   router.push(i18nRoute({ name: 'matches' }))
   modals.Onboarding()
} else if (query.get('action') === 'signin') {
   if (query.get('provider') === 'google' && gtm) {
      gtm.trackEvent({
         event: 'login',
         method: 'gmail'
      })
   }

   router.push(i18nRoute({ name: 'matches' }))
}

query.forEach((value, key) => {
   switch (key) {
      case 'gclid': {
         setCookie('gclid', value, 30)
         break
      }

      case 'utm_source': {
         setCookie('utm_source', value, 30)
         break
      }
      case 'utm_medium': {
         setCookie('utm_medium', value, 30)
         break
      }

      case 'utm_campaign': {
         setCookie('utm_campaign', value, 30)
         break
      }

      case 'utm_term': {
         setCookie('utm_term', value, 30)
         break
      }

      case 'utm_content': {
         setCookie('utm_content', value, 30)
         break
      }

      default: {
         break
      }
   }
})

const { onResult, onError, subscribeToMore: subscribeToMoreUser } = useQuery(getUser)
const { onResult: onResultNotify } = useSubscription(notify)
const { load: loadGiveAnalyticInfo } = useLazyQuery(giveAnalyticInfo)

onResultNotify((data) => {
   console.log(data)
})

onResult(({ data, loading }) => {
   const user = data?.user

   userStore.setLoading(loading)

   const lang = findDefaultLocale()

   let plan = 'basic'

   if (!loading) {
      if (user) {
         if (user.subscription.plan === 'INSIDER' && user.subscription.planType === 'RECURRENT') {
            plan = 'insider_plus'
         } else if (user.subscription.plan === 'INSIDER' && user.subscription.planType === 'ONE_TIME') {
            plan = 'insider_one'
         }

         if (!user.subscription.active) {
            const queryPay = query.get('pay')
            if (queryPay) {
               const queryDays = query.get('oneDays')
               if (queryPay === 'recurrent') {
                  createPayment.recurrent()
               } else if (queryPay === 'one' && queryDays) {
                  selectedIndexDay.value = insiderOneDays.value.indexOf(Number(queryDays))
                  createPayment.oneTime()
               }
            }
         }

         userStore.setUser(user)
         gtm?.push({
            event: 'params',
            user_id: user.id,
            user_auth: 1,
            bonus_applied: Object.values(user.usedFreeDays).filter((value) => value === true).length,
            sub: plan,
            is_trial: user.subscription.trial,
            card: user.cards.length > 0 ? 1 : 0,
            autopayment: user.subscription.autoPayment ? 1 : 0,
            was_sub: user.subscription.trialUsed ? 1 : 0,
            lang
         })

         subscribeToMoreUser({
            document: subscribeUser,
            updateQuery: (prev, { subscriptionData }) => {
               const user = subscriptionData.data.userUpdated

               if (user) {
                  userStore.setUser(user)
               }

               return prev
            },
            onError: (data) => {
               console.log(data)
            }
         })

         loadGiveAnalyticInfo()
      } else {
         if (queryAuth) {
            modals.Auth(queryAuth === 'signin')
         }
         gtm?.push({
            event: 'params',
            user_id: -1,
            user_auth: -1,
            bonus_applied: -1,
            sub: plan,
            is_trial: false,
            card: -1,
            autopayment: -1,
            was_sub: -1,
            lang
         })
      }
   }
})

onError(() => {
   const lang = findDefaultLocale()

   if (queryAuth) {
      modals.Auth(queryAuth === 'signin')
   }

   gtm?.push({
      event: 'params',
      user_id: -1,
      user_auth: -1,
      bonus_applied: -1,
      sub: 'basic',
      is_trial: false,
      card: -1,
      autopayment: -1,
      was_sub: -1,
      lang
   })
   userStore.setLoading(false)
})

const { subscribeToMore, onResult: onResultMatches } = useQuery(getFutureAndLiveMatches, { locale: locale.value })

onResultMatches(({ data }) => {
   const matches = data?.matches
   if (matches) {
      const matchesFiltered = matches.filter((match) => {
         return new Date(match.date).getTime() < Date.now() + 1000 * 60 * 10
      })
      const matchesInfo = matchesFiltered.map((match) => match.id)
      startConnection(matchesInfo)
   }
})

subscribeToMore({
   document: subscribeMatches
})

watch(
   () => globalStore.screenLock,
   (value) => {
      if (value) {
         document.querySelector('#app')?.classList.add('lock')
      } else {
         document.querySelector('#app')?.classList.remove('lock')
      }
   }
)
</script>

<template>
   <component :is="route.meta.layoutComponent">
      <RouterView />
   </component>
   <Teleport to="body">
      <div
         class="fixed top-64px bottom-16px left-1/2 -translate-x-1/2 justify-start w-260px z-11 pointer-events-none flex flex-col gap-y-12px text-12px leading-14px"
      >
         <template v-for="(notification, index) in globalStore.notifications" :key="index">
            <Transition
               appear
               enter-from-class="opacity-0 -translate-x-20px"
               enter-active-class="duration-500 transition-all"
               leave-active-class="duration-500 transition-all"
               leave-to-class="opacity-0 translate-x-0"
            >
               <div
                  v-if="notification.type === 'error' || notification.type === 'success'"
                  class="min-h-68px max-h-100px gradient-plate-900 rounded-10px shadow-black2 border border-gray-50/4 relative z-1 px-12px py-8px flex items-center gap-x-8px text-gray-50/80"
               >
                  <img
                     v-if="notification.type === 'error'"
                     src="/img/notifications/error.png"
                     srcset="/img/notifications/error@2.png 2x"
                     alt="error"
                     class="w-28px"
                  />
                  <img
                     v-else-if="notification.type === 'success'"
                     src="/img/notifications/success.png"
                     srcset="/img/notifications/success@2.png 2x"
                     alt="success"
                     class="w-28px"
                  />

                  <span class="break-words hyphens-auto line-clamp-6">{{ notification.text }}</span>

                  <div class="h-2px gradient-lava-700 absolute left-1/2 -translate-x-1/2 bottom-0 opacity-30 rounded-full w-90%"></div>
               </div>
            </Transition>
            <Transition
               appear
               enter-from-class="opacity-0 -translate-x-20px"
               enter-active-class="duration-500 transition-all"
               leave-active-class="duration-500 transition-all"
               leave-to-class="opacity-0 translate-x-0"
            >
               <router-link
                  v-if="notification.type === NotifyType.Match && notification.category === NotifyCategory.Status"
                  :to="i18nRoute({ name: 'match', params: { id: notification.matchId } })"
                  class="h-68px gradient-plate-900-reverse rounded-10px shadow-black2 border border-gray-50/4 relative z-1 overflow-hidden pointer-events-auto px-8px flex flex-col justify-center"
               >
                  <div class="flex content-center gap-x-4px">
                     <div class="text-yellow-700 flex item-start gap-x-3px">
                        <img :src="notification.team1.logo" alt="team1" class="h-20px -mt-4px" />
                        {{ notification.team1.name }}
                     </div>

                     vs.
                     <div class="text-yellow-700 flex item-start gap-x-3px">
                        <img :src="notification.team2.logo" alt="team2" class="h-20px -mt-4px" />
                        {{ notification.team2.name }}
                     </div>
                  </div>

                  <div class="mt-4px">
                     {{ $t('M_lMvua4o5hbGx_0hufoD') }} <span class="text-red-700 font-bold uppercase">{{ notification.status }}</span>
                  </div>

                  <img
                     src="/img/notifications/status.png"
                     srcset="/img/notifications/status@2.png 2x"
                     alt="date"
                     class="absolute top-1/2 -translate-y-1/2 right-0 opacity-10 w-100px -z-1"
                  />
                  <div class="h-2px gradient-lava-700 absolute left-8px right-8px bottom-0 opacity-30 rounded-full"></div>
               </router-link>
            </Transition>
            <Transition
               appear
               enter-from-class="opacity-0 -translate-x-20px"
               enter-active-class="duration-500 transition-all"
               leave-active-class="duration-500 transition-all"
               leave-to-class="opacity-0 translate-x-0"
            >
               <router-link
                  v-if="notification.type === NotifyType.Match && notification.category === NotifyCategory.Predict"
                  :to="i18nRoute({ name: 'match', params: { id: notification.matchId } })"
                  class="h-68px gradient-plate-900-reverse rounded-10px shadow-black2 border border-gray-50/4 relative z-1 overflow-hidden pointer-events-auto px-8px flex flex-col justify-center"
               >
                  <div class="flex content-center gap-x-4px">
                     <div class="text-yellow-700 flex item-start gap-x-3px">
                        <img :src="notification.team1.logo" alt="team1" class="h-20px -mt-4px" />
                        {{ notification.team1.name }}
                     </div>

                     vs.
                     <div class="text-yellow-700 flex item-start gap-x-3px">
                        <img :src="notification.team2.logo" alt="team2" class="h-20px -mt-4px" />
                        {{ notification.team2.name }}
                     </div>
                  </div>

                  <div class="mt-4px">
                     {{ $t('p9eyo-VhDZK9XA3zXyMrw') }}
                     <br />
                     <template v-if="true">
                        <span class="text-red-700 text-bold">{{ $t('UwfJZ95QbBqz7Ufs6RSQ-') }}</span> {{ $t('Cb_PDTsjEfavsEUvwpx75') }}
                     </template>
                  </div>

                  <img
                     src="/img/notifications/predict.png"
                     srcset="/img/notifications/predict@2.png 2x"
                     alt="date"
                     class="absolute top-1/2 -translate-y-1/2 right-0 opacity-10 w-100px -z-1"
                  />
                  <div class="h-2px gradient-lava-700 absolute left-8px right-8px bottom-0 opacity-30 rounded-full"></div>
               </router-link>
            </Transition>
            <Transition
               appear
               enter-from-class="opacity-0 -translate-x-20px"
               enter-active-class="duration-500 transition-all"
               leave-active-class="duration-500 transition-all"
               leave-to-class="opacity-0 translate-x-0"
            >
               <router-link
                  v-if="notification.type === NotifyType.Match && notification.category === NotifyCategory.Date"
                  :to="i18nRoute({ name: 'match', params: { id: notification.matchId } })"
                  class="h-68px gradient-plate-900-reverse rounded-10px shadow-black2 border border-gray-50/4 relative z-1 overflow-hidden pointer-events-auto px-8px flex flex-col justify-center"
               >
                  <div class="flex content-center gap-x-4px">
                     <div class="text-yellow-700 flex item-start gap-x-3px">
                        <img :src="notification.team1.logo" alt="team1" class="h-20px -mt-4px" />
                        {{ notification.team1.name }}
                     </div>

                     vs.
                     <div class="text-yellow-700 flex item-start gap-x-3px">
                        <img :src="notification.team2.logo" alt="team2" class="h-20px -mt-4px" />
                        {{ notification.team2.name }}
                     </div>
                  </div>

                  <div class="mt-4px">
                     {{ $t('hwXaQVS-EX_HRns27GOjQ') }}
                     <span class="text-red-700">{{ dayjs(notification.dateStart).locale(locale).format('D MMMM, HH:mm') }}</span>
                  </div>

                  <img
                     src="/img/notifications/date.png"
                     srcset="/img/notifications/date@2.png 2x"
                     alt="date"
                     class="absolute top-1/2 -translate-y-1/2 right-0 opacity-10 w-100px -z-1"
                  />
                  <div class="h-2px gradient-lava-700 absolute left-8px right-8px bottom-0 opacity-30 rounded-full"></div>
               </router-link>
            </Transition>
            <Transition
               appear
               enter-from-class="opacity-0 -translate-x-20px"
               enter-active-class="duration-500 transition-all"
               leave-active-class="duration-500 transition-all"
               leave-to-class="opacity-0 translate-x-0"
            >
               <router-link
                  v-if="notification.type === NotifyType.Match && notification.category === NotifyCategory.MapPick"
                  :to="i18nRoute({ name: 'match', params: { id: notification.matchId } })"
                  class="h-68px gradient-plate-900-reverse rounded-10px shadow-black2 border border-gray-50/4 relative z-1 overflow-hidden pointer-events-auto px-8px flex flex-col justify-center"
               >
                  <div class="flex content-center gap-x-4px">
                     <div class="text-yellow-700 flex item-start gap-x-3px">
                        <img :src="notification.team1.logo" alt="team1" class="h-20px -mt-4px" />
                        {{ notification.team1.name }}
                     </div>

                     vs.
                     <div class="text-yellow-700 flex item-start gap-x-3px">
                        <img :src="notification.team2.logo" alt="team2" class="h-20px -mt-4px" />
                        {{ notification.team2.name }}
                     </div>
                  </div>

                  <div class="mt-4px">{{ $t('HcyICH6O4FeNvhNKOlxpJ') }}</div>

                  <img
                     src="/img/notifications/map.png"
                     srcset="/img/notifications/map@2.png 2x"
                     alt="date"
                     class="absolute top-1/2 -translate-y-1/2 right-0 opacity-10 w-100px -z-1"
                  />
                  <div class="h-2px gradient-lava-700 absolute left-8px right-8px bottom-0 opacity-30 rounded-full"></div>
               </router-link>
            </Transition>
            <Transition
               appear
               enter-from-class="opacity-0 -translate-x-20px"
               enter-active-class="duration-500 transition-all"
               leave-active-class="duration-500 transition-all"
               leave-to-class="opacity-0 translate-x-0"
            >
               <router-link
                  v-if="notification.type === NotifyType.Match && notification.category === NotifyCategory.TeamChanged"
                  :to="i18nRoute({ name: 'match', params: { id: notification.matchId } })"
                  class="h-68px gradient-plate-900-reverse rounded-10px shadow-black2 border border-gray-50/4 relative z-1 overflow-hidden pointer-events-auto px-8px flex flex-col justify-center"
               >
                  <div class="flex content-center gap-x-4px">
                     <div class="text-yellow-700 flex item-start gap-x-3px">
                        <img :src="notification.team1.logo" alt="team1" class="h-20px -mt-4px" />
                        {{ notification.team1.name }}
                     </div>

                     vs.
                     <div class="text-yellow-700 flex item-start gap-x-3px">
                        <img :src="notification.team2.logo" alt="team2" class="h-20px -mt-4px" />
                        {{ notification.team2.name }}
                     </div>
                  </div>

                  <div class="mt-4px">{{ $t('xDRRT7fYlQXSd_8Hsfrbd') }}</div>

                  <img
                     src="/img/notifications/replacement.png"
                     srcset="/img/notifications/replacement@2.png 2x"
                     alt="date"
                     class="absolute top-1/2 -translate-y-1/2 right-0 opacity-10 w-100px -z-1"
                  />
                  <div class="h-2px gradient-lava-700 absolute left-8px right-8px bottom-0 opacity-30 rounded-full"></div>
               </router-link>
            </Transition>
         </template>
      </div>
   </Teleport>

   <component :is="modalComponent" />
</template>
