<script setup lang="ts">
import { useRouter } from 'vue-router'
import { sendEvent } from '@/composables/gtm'
import { modals } from '@/components/UI/Modal'

const router = useRouter()

const handler = (text: string) => {
   sendEvent('cta', text, '5steps_comm', undefined, router)
   modals.Auth(false)
}
</script>

<template>
   <div
      class="flex <tablethd:flex-col <tablethd:items-center gap-x-80px <laptop:gap-x-40px <laptop:justify-between pl-100px <fullhd:px-36px <tablethd:px-4px"
   >
      <picture class="tablethd:-translate-y-20px flex-shrink-0">
         <source
            media="(max-width: 767px)"
            srcset="/img/what-you-get/community/bg-mobile.png 1x, /img/what-you-get/community/bg-mobile@2.png 2x"
         />
         <img
            srcset="/img/what-you-get/community/bg@2.png 2x"
            src="/img/what-you-get/community/bg.png"
            alt="community"
            class="w-502px h-301px <tablet:w-328px <tablet:h-300px"
         />
      </picture>

      <div class="<tablethd:mb-24px <tablethd:px-8px">
         <header class="relative flex items-center gap-x-16px mb-24px <tablethd:hidden">
            <div class="w-2px h-22px rounded-10px bg-yellow-700 shadow-yellow"></div>
            <h2>{{ $t('P1tNUaQ0iH0ZlUf_l_RwI') }}</h2>
         </header>

         <ul class="m-semibold <tablet:s-medium2 tablet:mt-16px mb-24px tablethd:ml-18px flex flex-col gap-y-12px">
            <li class="flex items-center gap-x-12px">
               <div class="flex-shrink-0 w-36px aspect-square rounded-10px bg-gray-50/4 border border-gray-50/2 flexc backdrop-blur-25">
                  <div class="drop-shadow-yellow"><i class="i-custom-discord-outline text-20px text-yellow-700 block"></i></div>
               </div>
               {{ $t('-0bg1xa-GDAnwC-JuYEcl') }}
            </li>
            <li class="flex items-center gap-x-12px">
               <div class="flex-shrink-0 w-36px aspect-square rounded-10px bg-gray-50/4 border border-gray-50/2 flexc backdrop-blur-25">
                  <div class="drop-shadow-yellow"><i class="i-custom-discussion-outline text-20px text-yellow-700 block"></i></div>
               </div>
               {{ $t('eQ2XKZ7p2g3yBBTC0OzKJ') }}
            </li>
            <li class="flex items-center gap-x-12px">
               <div class="flex-shrink-0 w-36px aspect-square rounded-10px bg-gray-50/4 border border-gray-50/2 flexc backdrop-blur-25">
                  <div class="drop-shadow-yellow"><i class="i-custom-moon-outline text-20px text-yellow-700 block"></i></div>
               </div>
               {{ $t('dashboard.whatInside.card5.listDescription1.item3') }}
            </li>
            <li class="flex items-center gap-x-12px">
               <div class="flex-shrink-0 w-36px aspect-square rounded-10px bg-gray-50/4 border border-gray-50/2 flexc backdrop-blur-25">
                  <div class="drop-shadow-yellow"><i class="i-custom-rocket-outline text-20px text-yellow-700 block"></i></div>
               </div>
               {{ $t('IatSS57DZNuCfVGFFh7X6') }}
            </li>
         </ul>
      </div>

      <button
         class="block text-center w-304px py-16px btn-lava-700 tablet:hidden"
         @click="handler($t('subscription.profileWidget.subscriptionWidget.button.state1', {}, { locale: 'en' }))"
      >
         {{ $t('subscription.profileWidget.subscriptionWidget.button.state1') }}
      </button>
   </div>
</template>

<style scoped></style>
