/* eslint-disable no-extra-boolean-cast */
import '@unocss/reset/tailwind.css'
import 'virtual:uno.css'
import '@/assets/css/default.css'

import { createApp, provide, h } from 'vue'
import { i18n } from '@/i18n'
import { createPinia } from 'pinia'

import { ApolloClient, createHttpLink, InMemoryCache, split } from '@apollo/client/core'
import { getMainDefinition } from '@apollo/client/utilities'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { DefaultApolloClient } from '@vue/apollo-composable'
import { createClient as createWSClient } from 'graphql-ws'
import * as Sentry from '@sentry/vue'

import VueTippy from 'vue-tippy'

import { initCurrency } from '@/composables/tariffs'

import { createGtm } from '@gtm-support/vue-gtm'

import App from './App.vue'
import router from './router'

import 'dayjs/locale/ru'
import 'tippy.js/dist/svg-arrow.css'

const wsLink = new GraphQLWsLink(
   createWSClient({
      url: import.meta.env.VITE_GRAPHQL_WS,
      retryAttempts: Number.POSITIVE_INFINITY,
      shouldRetry: () => true,
      keepAlive: 10_000
   })
)

const httpLink = createHttpLink({
   uri: import.meta.env.VITE_GRAPHQL_URL,
   credentials: 'include'
})

const link = split(
   ({ query }) => {
      const definition = getMainDefinition(query)
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
   },
   wsLink,
   httpLink
)

const cache = new InMemoryCache({
   typePolicies: {
      Query: {
         fields: {
            events: {
               read(existing, { args }: { args: Record<string, number> | null }) {
                  return existing && args && existing.slice(0, args.skip + args.limit)
               },
               keyArgs: [],
               merge(existing: Array<{ __ref: string }> = [], incoming: Array<{ __ref: string }>) {
                  const existingSet = new Set(existing.map((item) => item.__ref))
                  incoming.forEach((item) => {
                     existingSet.add(item.__ref)
                  })
                  return Array.from(existingSet, (item) => ({ __ref: item }))
               }
            }
         }
      },
      TeamModel: {
         fields: {
            logo_x1: {
               merge(existing, incoming) {
                  if (!incoming) return existing
                  return incoming
               }
            },
            logo_x2: {
               merge(existing, incoming) {
                  if (!incoming) return existing
                  return incoming
               }
            }
         }
      }
   }
})

const apolloClient = new ApolloClient({
   credentials: 'include',
   defaultOptions: {
      watchQuery: {
         fetchPolicy: 'cache-and-network'
      }
   },
   link,
   cache,
   connectToDevTools: import.meta.env.VITE_IS_DEV
})

const app = createApp({
   setup() {
      provide(DefaultApolloClient, apolloClient)
   },

   render: () => h(App)
})

Sentry.init({
   app,
   dsn: 'https://8d9e267bebb0b124bfcbe1bef4733b14@o4504648020918272.ingest.sentry.io/4506048091521024',
   allowUrls: [/https?:\/\/([\dA-Za-z-]+\.)?datalouna\.io/],
   integrations: [Sentry.replayIntegration()],
   tracesSampleRate: 0,
   replaysOnErrorSampleRate: 1,
   enabled: !import.meta.env.VITE_IS_DEV,
   ignoreErrors: ['chrome-extension', /^chrome-extension:\/\//],
   denyUrls: [/^chrome-extension:\/\//, /^moz-extension:\/\//]
})

app.use(i18n)

app.use(createPinia())

app.use(VueTippy, {
   directive: 'tippy',
   component: 'tippy',
   defaultProps: {
      delay: 0,
      duration: 100,
      maxWidth: 'none',
      theme: 'info',
      arrow: `<svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.97875 6.41954L6.96827 5.13002C6.37446 4.37335 5.7778 3.61811 5.1897 2.85764C5.14791 2.79709 5.09142 2.74816 5.02553 2.71542C4.95965 2.68269 4.88653 2.66723 4.81303 2.67049C3.32827 2.6743 1.84335 2.6743 0.358273 2.67049C0.219225 2.67049 0.100178 2.69097 0.0311302 2.83192C-0.0426793 2.98192 0.0263682 3.09097 0.117321 3.2024C1.6605 5.09287 3.20335 6.98399 4.74589 8.87573C5.72176 10.0722 6.69748 11.2689 7.67303 12.4657C7.90637 12.7514 8.0597 12.7514 8.29637 12.4619C10.8024 9.38732 13.3089 6.31287 15.8159 3.23859C15.8512 3.19846 15.8841 3.15633 15.9145 3.1124C15.9427 3.07207 15.9597 3.02497 15.9637 2.97592C15.9677 2.92686 15.9586 2.87762 15.9374 2.83323C15.9161 2.78885 15.8834 2.75092 15.8426 2.72332C15.8019 2.69573 15.7545 2.67947 15.7054 2.67621C15.5697 2.66478 15.4321 2.67145 15.2959 2.67145C13.9354 2.67145 12.5754 2.67526 11.2149 2.66764C11.1215 2.66229 11.0282 2.68118 10.9442 2.72247C10.8602 2.76377 10.7883 2.82608 10.7354 2.90335C9.86161 4.02954 8.9797 5.14859 8.10018 6.27002C8.06637 6.31145 8.03208 6.35383 7.97875 6.41954Z" fill="#DE3A1E" transform="rotate(180 8 8)"/>
</svg>
`
   }
})

app.use(
   createGtm({
      id: 'GTM-TMZFSMJ3',
      enabled: import.meta.env.PROD,
      debug: import.meta.env.DEV,
      loadScript: true
   })
)

app.use(router)

initCurrency()

app.mount('#app')
