<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { useQuery } from '@vue/apollo-composable'
import { getSuccessTracker } from '@/gql/requests'
import LoadingBG from '@/components/LoadingBG.vue'
import StatMonth from './StatMonth.vue'
import InputMoney from '@/components/UI/InputMoney.vue'
import { Tippy } from 'vue-tippy'
import CircleTwoLine from '@/components/CircleTwoLine.vue'

const { result, loading } = useQuery(getSuccessTracker)

const amount = ref<number | ''>(300)

const profit = ref(0)
const matchesSuccess = ref(0)

const widthGraphs = ref(166)
const strokeWidthGraphs = ref(8)

function onProgressWinrate(progress: number) {
   matchesSuccess.value = Math.round(progress * (result.value?.statistics.recommended.successful || 0))
}

function onProgressProfit(progress: number) {
   if (!result?.value) return
   const moneyWin = Math.round(
      result.value.statistics.recommended.successful * (result.value.statistics.recommended.avgCoff - 1) * (amount.value || 0) -
         (100 - result.value.statistics.recommended.successful) * (amount.value || 0)
   )
   profit.value = Math.round(progress * moneyWin)
}

const tippyHowIsWorksPosition = ref<'bottom' | 'bottom-start'>('bottom-start')

onMounted(() => {
   if (window.innerWidth < 1024) {
      widthGraphs.value = 142
      strokeWidthGraphs.value = 6

      tippyHowIsWorksPosition.value = 'bottom'
   }
})

const onUpdateInputAmount = (event: Event) => {
   if (window.innerWidth < 1024 && event instanceof InputEvent) {
      const target = event.target as HTMLInputElement

      const span = document.createElement('span')
      span.style.visibility = 'hidden'
      span.style.position = 'absolute'
      span.style.whiteSpace = 'nowrap'

      span.textContent = String(amount.value)

      document.body.append(span)

      target.style.width = Math.min(span.offsetWidth, 100) + 'px'

      span.remove()
   }

   const success = result.value?.statistics.recommended.successful ?? 0
   const avgCoff = result.value?.statistics.recommended.avgCoff ?? 0

   profit.value = Math.round(success * (avgCoff - 1) * (amount.value || 0) - (100 - success) * (amount.value || 0))
}
</script>

<template>
   <article class="flex flex-col gap-y-20px <tablet:gap-y-16px overflow-hidden">
      <div class="px-20px py-8px rounded-10px bg-black-850 h3-semibold <laptop:mx-16px">{{ $t('t7BCbQ7tMZktGRtkDo-XL') }}</div>
      <div class="tablethd:h-180px rounded-20px tablethd:bg-black-850 result-gradient-mobile relative z-1 <laptop:mx-16px">
         <div class="h-full grid grid-cols-[257px_1fr] <tablethd:grid-cols-1 <tablethd:pt-12px">
            <div
               class="grid grid-cols-[auto] <tablethd:flex <tablethd:items-center <tablethd:gap-12px <tablethd:flex-wrap justify-center self-center"
            >
               <div class="l-semibold gap-x-8px <tablethd:gap-x-12px flex items-center">
                  <hr class="flex-auto h-2px <tablethd:h-1px <tablethd:w-32px <tablethd:order-first rounded-full gradient-lava-700" />
                  {{ $t('q_pg9ZLdQNAvUt9c_MZ1H') }}
                  <hr
                     class="flex-auto h-2px <tablethd:h-1px <tablethd:w-32px <tablethd:order-first rounded-full gradient-lava-700 <tablethd:hidden"
                  />
               </div>
               <div class="h2-semibold <tablethd:l-semibold <tablethd:-ml-8px">100 {{ $t('_TmKor2a0UVX0MmJZg4Mb') }}</div>
               <hr class="h-1px w-32px rounded-full gradient-lava-700 tablethd:hidden" />

               <div class="<tablethd:w-full <tablethd:flex <tablethd:justify-center">
                  <tippy
                     :placement="tippyHowIsWorksPosition"
                     :hide-on-click="false"
                     class="tablethd:absolute top-8px right-8px z-2 inline-block"
                  >
                     <div class="rounded-full bg-black-800 py-4px pl-12px pr-4px flex items-center gap-x-8px s-semibold select-none">
                        {{ $t('9nucw_rOfussWtwBc8bDj') }}
                        <i class="i-custom-info-outline text-16px"></i>
                     </div>
                     <template #content>
                        <div class="flex flex-col gap-y-12px">
                           <div class="flex items-start gap-x-12px">
                              <div class="w-32px h-32px flexc border border-gray-50 rounded-10px">
                                 <i class="i-custom-bill-arrow text-16px"></i>
                              </div>
                              <div class="flex flex-col gap-y-4px">
                                 <span class="s-semibold text-yellow-700">{{ $t('SUjLhQkIu61K7EgY9OB8H') }}</span>
                                 <p class="text-10px leading-[140%] font-medium max-w-276px">
                                    {{ $t('XhhVaCaUMjhI-Ey7WhCfS') }}
                                 </p>
                              </div>
                           </div>
                           <div class="h-1px bg-black-700 rounded-full"></div>
                           <div class="flex items-start gap-x-12px">
                              <div class="w-32px h-32px flexc border border-gray-50 rounded-10px">
                                 <i class="i-custom-profit-outline text-16px"></i>
                              </div>
                              <div class="flex flex-col gap-y-4px">
                                 <span class="s-semibold text-yellow-700">{{ $t('Nsslan3m7hDjtUMr44zfX') }}</span>
                                 <p class="text-10px leading-[140%] font-medium max-w-276px">{{ $t('x_8ubDqXBbbERbWwFN-wl') }}</p>
                              </div>
                           </div>
                           <div class="h-1px bg-black-700 rounded-full"></div>
                           <div class="flex items-start gap-x-12px">
                              <div class="w-32px h-32px flexc border border-gray-50 rounded-10px">
                                 <i class="i-custom-calculator-outline text-16px"></i>
                              </div>
                              <div class="flex flex-col gap-y-4px">
                                 <span class="s-semibold text-yellow-700">{{ $t('Mrp5sUbpOf5-8QqcNsp9Z') }}</span>
                                 <p class="text-10px leading-[140%] font-medium max-w-276px">
                                    {{ $t('sKaUk1DdiAZXbhWMRfoWp') }}
                                 </p>
                              </div>
                           </div>
                        </div>
                     </template>
                  </tippy>
               </div>
            </div>
            <div
               class="rounded-l-full <tablethd:rounded-10px relative z-1 overflow-hidden result-gradient flex items-center <tablethd:flex-col gap-x-54px px-40px <tablethd:gap-y-14px <tablethd:px-16px <tablethd:py-14px"
            >
               <div class="<tablethd:hidden">
                  <div class="xs-medium text-gray-50/50 uppercase">{{ $t('AUa0trOfZiLVYxwEQu0Bl') }}</div>
                  <LoadingBG
                     v-if="loading"
                     class="h-20px mt-4px w-50px loading-bg-after overflow-hidden relative border border-gray-50/4 rounded-6px"
                  />
                  <div v-else class="h3-semibold">{{ result?.statistics.recommended.avgCoff }}</div>
               </div>

               <div class="flex gap-x-40px <tablethd:gap-x-28px <tablethd:grid <tablethd:grid-cols-[134px_134px]">
                  <div class="flex flex-col items-center gap-y-14px">
                     <div class="relative w-156px h-156px <tablethd:w-132px <tablethd:h-132px flexc">
                        <div
                           class="w-120px h-120px <tablethd:w-100px <tablethd:h-100px rounded-full bg-black-850 border border-gray-50/8 relative flex flex-col items-center pt-12px"
                        >
                           <i class="i-custom-target-outline text-16px <tablethd:text-12px text-green-500/50"></i>
                           <span
                              class="h2-semibold <tablethd:h3-semibold mt-10px mb-4px <tablethd:mt-12px <tablethd:mb-12px text-green-500"
                           >
                              <LoadingBG
                                 v-if="loading"
                                 class="w-65px h-28px <tablethd:w-44px <tablethd:h-14px my-5px loading-bg-after overflow-hidden relative border border-gray-50/4 rounded-6px"
                              />
                              <template v-else> {{ matchesSuccess }}% </template>
                           </span>

                           <span class="s-medium2 <tablethd:xs-medium">{{ $t('uB8p1MT8jjm9hdWHAzVQ-') }}</span>

                           <div class="absolute inset-0 bg-gray-50/4 rounded-inherit">
                              <div class="absolute inset-0 result-bg-circle-green rounded-inherit"></div>
                           </div>
                        </div>
                        <template v-if="loading">
                           <div class="loading-bg-after rounded-full absolute -inset-5px overflow-hidden z-1"></div>
                           <div class="absolute -inset-5px border border-10px <tablethd:border-8px rounded-full border-black-850"></div>
                        </template>
                        <template v-else>
                           <CircleTwoLine
                              :percentage="result?.statistics.recommended.successful ?? 0"
                              :size="widthGraphs"
                              :line-width="strokeWidthGraphs"
                              :margin="1"
                              :draw-time="2"
                              gradient
                              :gradient-color="{
                                 top: { start: 'rgba(44, 194, 86, 0.6)', end: 'rgba(44, 194, 86, 1)' },
                                 bottom: {
                                    start: 'rgba(223, 28, 72, 0.5)',
                                    end: 'rgba(223, 28, 72, 1)'
                                 }
                              }"
                              :gradient-position="{
                                 top: { x1: 50, y1: 0, x2: 50, y2: 100 },
                                 bottom: { x1: 50, y1: 0, x2: 50, y2: 100 }
                              }"
                              class="absolute"
                              @progress="onProgressWinrate"
                           />
                        </template>

                        <div class="absolute inset-0 rounded-full overflow-hidden -z-1">
                           <i class="i-custom-lines text-320px absolute -top-74px <tablethd:-top-96px -left-30px text-brown-700"></i>
                           <i
                              class="i-custom-lines text-320px absolute -bottom-58px <tablethd:-bottom-103px -right-20px text-brown-700"
                           ></i>
                        </div>
                     </div>
                     <div class="flex items-center gap-x-8px tablethd:hidden">
                        <span class="xs-medium text-gray-50/50 uppercase">{{ $t('k958-5K0b_WjjjkkNi8bw') }}</span>
                        <LoadingBG v-if="loading" class="w-30px h-17px overflow-hidden relative border border-gray-50/4 rounded-6px" />
                        <span v-else class="l-semibold leading-[100%]">{{ result?.statistics.recommended.avgCoff }}</span>
                     </div>
                  </div>
                  <div class="flex items-center <tablethd:flex-col <tablethd:gap-y-10px">
                     <div class="relative w-156px h-156px <tablethd:w-132px <tablethd:h-132px flexc z-1">
                        <div
                           class="w-120px h-120px <tablethd:w-100px <tablethd:h-100px rounded-full bg-black-850 border border-gray-50/8 relative flex flex-col items-center pt-12px"
                        >
                           <i class="i-custom-profit-outline text-16px <tablethd:text-12px text-yellow-700/50"></i>
                           <span
                              class="<tablethd:h3-semibold mt-10px mb-4px <tablethd:mt-12px <tablethd:mb-12px text-yellow-700"
                              :class="profit > 1000000 ? 'h3-semibold' : 'h2-semibold'"
                           >
                              <LoadingBG
                                 v-if="loading"
                                 class="w-65px h-28px my-5px <tablethd:w-44px <tablethd:h-14px loading-bg-after overflow-hidden relative border border-gray-50/4 rounded-6px"
                              />
                              <template v-else> ${{ profit }}</template>
                           </span>
                           <span class="s-medium2 <tablethd:xs-medium">{{ $t('2n3LlFT-1JPrCDwuOS3uC') }}</span>

                           <div class="absolute inset-0 bg-gray-50/4 rounded-inherit">
                              <div class="absolute inset-0 result-bg-circle-yellow rounded-inherit"></div>
                           </div>
                        </div>
                        <template v-if="loading">
                           <div class="loading-bg-after rounded-full absolute -inset-5px overflow-hidden z-1"></div>
                           <div class="absolute -inset-5px border border-10px <tablethd:border-8px rounded-full border-black-850"></div>
                        </template>
                        <template v-else>
                           <CircleTwoLine
                              :percentage="88"
                              :size="widthGraphs"
                              :line-width="strokeWidthGraphs"
                              :margin="1"
                              :draw-time="2"
                              gradient
                              :gradient-color="{
                                 top: {
                                    start: '#876D1A',
                                    end: '#443710'
                                 },
                                 bottom: {
                                    start: 'rgba(241, 185, 10, 1)',
                                    end: 'rgba(241, 185, 10, 1)'
                                 }
                              }"
                              :gradient-position="{
                                 top: { x1: 50, y1: 0, x2: 50, y2: 100 },
                                 bottom: { x1: 50, y1: 0, x2: 50, y2: 100 }
                              }"
                              class="absolute tablethd:-rotate-90"
                              @progress="onProgressProfit"
                           />
                        </template>
                        <div class="absolute inset-0 rounded-full overflow-hidden -z-1">
                           <i class="i-custom-lines text-320px absolute -top-74px <tablethd:-top-96px -left-30px text-brown-700"></i>
                           <i
                              class="i-custom-lines text-320px absolute -bottom-58px <tablethd:-bottom-103px -right-20px text-brown-700"
                           ></i>
                        </div>
                     </div>

                     <LoadingBG v-if="loading" class="tablethd:hidden w-120px h-25px border border-gray-50/4 rounded-full bg-black-850" />

                     <Transition
                        appear
                        enter-from-class="tablethd:opacity-0 transition-left"
                        enter-active-class="duration-1000 delay-1000 transition-all"
                     >
                        <label
                           v-if="!loading"
                           class="tablethd:-ml-4px pl-20px pr-5px py-5px <tablethd:py-2px <tablethd:px-12px rounded-r-10px rounded-l-2px <tablethd:rounded-full bg-gray-50/4 border border-gray-50/2 <tablethd:border-yellow-700/30 flex items-center gap-x-12px <tablethd:gap-x-8px backdrop-blur-10"
                        >
                           <span class="s-medium <tablethd:hidden">
                              {{ $t('DMcfygFeSsTnH6fdFYarK') }} <br />
                              {{ $t('HfjaUJ0rOTXnsgqPPx96r') }}
                           </span>
                           <span class="text-10px font-500 tablethd:hidden">{{ $t('zE7E5nCGNoAFN1sTqh6uk') }}</span>
                           <hr class="w-1px h-20px <tablethd:h-12px bg-gray-800 rounded-full" />
                           <div
                              class="tablethd:w-112px tablethd:py-7px tablethd:px-12px l-semibold flex items-center tablethd:bg-black-800/80 rounded-10px tablethd:border border-yellow-700/30 &hover:border-yellow-700/50 transition-colors focus-visible:border-yellow-700/50 focus-within:border-yellow-700/50"
                           >
                              <i class="i-custom-stake-outline text-16px <tablethd:text-12px text-yellow-700 flex-shrink-0"></i>
                              <span class="ml-12px tablethd:mr-4px <tablethd:ml-4px">$</span>
                              <InputMoney
                                 v-model="amount"
                                 :min="0"
                                 :max-length="4"
                                 :decimal-places="0"
                                 select-focus
                                 class="leading-0 <tablethd:m-semibold outline-none bg-transparent <tablethd:w-26px <tablethd:min-w-26px <tablethd:max-w-48px w-full h-full"
                                 @input="onUpdateInputAmount"
                              />
                           </div>
                        </label>
                     </Transition>
                  </div>
               </div>

               <img
                  src="/src/assets/img/success-tracker/lines.svg"
                  alt="lines"
                  class="absolute top-0 left-0 -z-1 pointer-events-none <tablethd:hidden"
               />
            </div>
         </div>
         <div class="absolute inset-0 -z-1 overflow-hidden rounded-inherit pointer-events-none <tablethd:hidden">
            <i class="i-custom-triangle-solid text-320px gradient-plate-900 absolute -top-60px -left-200px rotate-180"></i>
            <i class="i-custom-triangle-solid text-320px gradient-plate-900-reverse absolute -bottom-190px -left-30px"></i>
            <i class="i-custom-triangle-solid text-320px gradient-plate-900 absolute -top-60px -right-200px rotate-180 -scale-x-100"></i>
            <i class="i-custom-triangle-solid text-320px gradient-plate-900-reverse absolute -bottom-190px -right-30px"></i>
         </div>
      </div>
      <div
         v-if="loading"
         class="grid grid-cols-6 gap-x-10px <tablethd:flex <tablethd:overflow-x-auto no-scrollbar <tablethd:snap-x <tablethd:snap-mandatory <tablethd:scroll-px-16px <laptop:px-16px"
      >
         <LoadingBG
            v-for="i in 6"
            :key="i"
            class="<tablethd:w-200px h-130px overflow-hidden relative border border-gray-50/4 rounded-6px bg-black-850"
         />
      </div>
      <div
         v-else
         class="grid grid-cols-6 gap-x-10px <tablethd:flex <tablethd:overflow-x-auto no-scrollbar <tablethd:snap-x <tablethd:snap-mandatory <tablethd:scroll-px-16px <laptop:px-16px"
      >
         <StatMonth
            v-for="item in result?.statistics.lastSixMonths"
            :key="item.id"
            :amount="amount"
            :predictions="item.predictions"
            :winrate="Number(item.winrate)"
            :odds="Number(item.raisedPrematch)"
            :year="item.year"
            :month="Number(item.month)"
         />
      </div>
   </article>
</template>

<style scoped>
.result-bg-circle-green {
   background: radial-gradient(50% 50% at 50% -4%, rgba(44, 194, 86, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
}
.result-bg-circle-yellow {
   background: radial-gradient(50% 50% at 50% -4%, rgba(241, 185, 10, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
}

.result-gradient::before {
   content: '';
   --uno: 'tablethd:w-1022px absolute top-0 bottom-0 right-238px -z-1 rounded-inherit pointer-events-none';
   background-image: linear-gradient(90deg, rgba(41, 42, 51, 1) 0%, rgba(41, 42, 51, 0) 100%);
}

@screen tablethd {
   .transition-left {
      transform: translateX(-10px);
   }
}

@screen lt-tablethd {
   .glow-coef {
      position: relative;
      z-index: 1;
   }
   .glow-coef::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 10px;
      background: radial-gradient(43% 42% at 50% 0%, rgba(241, 185, 10, 0.12) 0%, rgba(29, 30, 36, 0) 100%);
      z-index: -1;
   }
   .header-gradient {
      background: linear-gradient(180deg, #292a33 -56.71%, rgba(41, 42, 51, 0) 48.07%);
   }

   .result-gradient::before {
      display: none;
   }

   .result-gradient-mobile {
      background: linear-gradient(180deg, #292a33 -85.38%, rgba(41, 42, 51, 0) 185%);
   }

   .result-screens-gradient {
      background: linear-gradient(0deg, #292a33 -87.39%, rgba(41, 42, 51, 0) 72.9%);
   }
}
</style>
