<script setup lang="ts">
import { ref, computed, onMounted } from 'vue'
import InputMoney from '@/components/UI/InputMoney.vue'
import Button from '@/components/UI/Button.vue'

defineProps<{
   buttonText: string
   loading?: boolean
}>()

const emit = defineEmits<{
   submit: [code: string, clear: () => void]
   back: []
}>()

const code = ref<(number | '')[]>(['', '', '', ''])
const wholeCode = computed(() => code.value.join(''))
const codeLength = code.value.length
const codeInputs = ref<InstanceType<typeof InputMoney>[]>([])

const focus = (index = 0) => {
   if (!codeInputs.value[index]) {
      return false
   }

   codeInputs.value[index].focus()
   codeInputs.value[index].select()

   return true
}

const focusBack = (index: number) => {
   if (index === 0) {
      return false
   }

   return focus(index - 1)
}

const focusNext = (index: number) => {
   if (code.value[index].toString().length !== 1) {
      return false
   }

   return focus(index + 1)
}

const remove = (index: number) => {
   for (let index_ = index; index_ < code.value.length; index_++) {
      code.value[index_] = ''
   }

   focusBack(index)
}

const paste = (event: ClipboardEvent) => {
   if (!event.clipboardData) {
      return
   }

   const data = event.clipboardData.getData('text').trim().slice(0, codeLength)

   if (data.length !== codeLength || Number.isNaN(Number(data))) {
      return
   }

   for (let index = 0; index < code.value.length; index++) {
      code.value[index] = Number(data[index])
   }

   focus(codeLength - 1)
   checkCode(codeLength - 1)
}

const clear = () => {
   for (let index = 0; index < codeLength; index++) {
      code.value[index] = ''
   }

   focus()
}

const checkCode = (index: number) => {
   if (focusNext(index)) {
      return
   }

   if (wholeCode.value.length !== codeLength) {
      return
   }

   if (Number.isNaN(Number(wholeCode.value))) {
      return
   }

   emit('submit', wholeCode.value, clear)
}

onMounted(() => {
   focus()
})
</script>

<template>
   <div class="flex flex-col gap-y-16px">
      <button class="flex items-center gap-x-8px s-semibold text-yellow-700 &hover:text-orange-900 trs" @click="emit('back')">
         <i class="i-custom-long-arrow-outline text-14px rotate-180deg"></i>
         {{ $t('-4cfiqJ5ECv5IdMiWLJ8j') }}
      </button>
      <div class="flex flex-col gap-y-24px">
         <h2 class="h3-semibold" v-html="$t('i32t4nsnsi9a9Pi9906Vi')"></h2>
         <div class="flex justify-center gap-x-8px">
            <label
               v-for="(_, i) in code"
               :key="i"
               class="text-32px w-64px bg-black-800 border border-gray-50/4 rounded-10px py-16px cursor-text"
            >
               <InputMoney
                  ref="codeInputs"
                  v-model="code[i]"
                  type="text"
                  maxlength="1"
                  :disabled="loading"
                  autocomplete="one-time-code"
                  flat
                  class="text-center"
                  select-focus
                  :max-length="1"
                  @keydown.enter="checkCode(i)"
                  @keydown.delete.prevent="remove(i)"
                  @keydown.left.prevent="focusBack(i)"
                  @keydown.right.prevent="focusNext(i)"
                  @input="checkCode(i)"
                  @paste.prevent="paste"
               />
            </label>
         </div>
         <Button class="p-14px m-semibold" :load="loading" :disable="loading || wholeCode.length !== codeLength">{{ buttonText }}</Button>
      </div>
   </div>
</template>
