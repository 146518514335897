<script setup lang="ts">
import { ref, reactive, computed, watchEffect, watch } from 'vue'
import { locale } from '@/i18n'
import { modals } from '../../index'
import Modal from '../../Modal.vue'
import Input from '@/components/UI/InputDefault.vue'
import Button from '@/components/UI/Button.vue'
import ButtonSocial from '@/components/UI/ButtonSocial.vue'
import OTP from './OTP.vue'
import { useUserStore } from '@/stores/user'
import { validateEmail } from '@/utils'
import { useI18n } from 'vue-i18n'
import { useMutation } from '@vue/apollo-composable'
import { login, sendCodeToEmail, registration, UserLang, recoverPassword } from '@/gql/requests'
import { getKeyFromBackendError } from '@/composables/errors'
import { ApolloError } from '@apollo/client'
import { useGtm } from '@gtm-support/vue-gtm'

const props = withDefaults(
   defineProps<{
      signIn?: boolean
   }>(),
   {
      signIn: true
   }
)

const userStore = useUserStore()
const { t } = useI18n()
const gtm = useGtm()

const { mutate: mutateSignIn, loading: loadingSignIn } = useMutation(login)
const { mutate: mutateGetCodeToEmail, loading: loadingGetCodeToEmail } = useMutation(sendCodeToEmail)
const { mutate: mutateSignUp, loading: loadingSignUp } = useMutation(registration)
const { mutate: mutateResetPassword, loading: loadingResetPassword } = useMutation(recoverPassword)

const isActive = ref(true)
const status = ref<'auth' | 'resetPassword' | 'otpResetPassword' | 'optRegistration'>('auth')
const signInIsActive = ref(props.signIn)

const email = ref<string>()
const password = ref<string>()
const passwordRepeat = ref<string>()

const errorText = ref('')
const errorInput = reactive({
   email: false,
   password: false,
   passwordRepeat: false
})

const googleSignIn = computed(() => {
   const query = window.location.search ? window.location.search + '&' : '?'
   const url = `${import.meta.env.VITE_API_URL}/api/auth/google${query}action=signin&provider=google`
   return url
})

const googleSignUp = computed(() => {
   const query = window.location.search ? window.location.search + '&' : '?'
   const url = `${import.meta.env.VITE_API_URL}/api/auth/google${query}action=signup&locale=${locale.value}&tz=${getUtc()}&provider=google`
   return url
})

function setDefaultError() {
   errorText.value = ''
   errorInput.email = false
   errorInput.password = false
   errorInput.passwordRepeat = false
}

function clearPassword() {
   password.value = ''
   passwordRepeat.value = ''
}

function onChangeTab(isSignIn: boolean) {
   if ((isSignIn && signInIsActive.value) || status.value !== 'auth') {
      return
   }

   setDefaultError()
   clearPassword()
   signInIsActive.value = isSignIn
}

watchEffect(() => {
   if (!userStore.loading && userStore.user) {
      modals.clear()
   }
})

function getUtc() {
   const result = (new Date().getTimezoneOffset() * -1) / 60
   return 'UTC' + (result > 0 ? '+' + result : '' + result)
}

function validateForm(isSignIn = true) {
   setDefaultError()

   const emptyEmail = !email.value?.trim()
   const emptyPassword = !password.value?.trim()
   const emptyPasswordRepeat = !isSignIn && !passwordRepeat.value?.trim()

   if (emptyEmail || emptyPassword || emptyPasswordRepeat) {
      errorText.value = t('errors.fieldsCannotBeEmpty')
      errorInput.email = emptyEmail
      errorInput.password = emptyPassword
      if (!isSignIn) errorInput.passwordRepeat = emptyPasswordRepeat
      return false
   }

   if (!validateEmail(email.value!)) {
      errorText.value = t('errors.invalidEmail')
      errorInput.email = true
      return false
   }

   if (!isSignIn && password.value !== passwordRepeat.value) {
      errorText.value = t('errors.passwordsDoNotMatch')
      errorInput.password = true
      errorInput.passwordRepeat = true
      return false
   }

   return true
}

function handleApolloError(error: unknown) {
   if (error instanceof ApolloError && error.graphQLErrors.length > 0) {
      errorText.value = t(getKeyFromBackendError(error.graphQLErrors[0].extensions?.code))
   } else {
      errorText.value = t('errors.backend.UNKNOWN')
   }
}

function onSignIn() {
   if (!validateForm(true)) return

   mutateSignIn({
      email: email.value!,
      password: password.value!
   })
      .then(() => {
         gtm?.trackEvent({
            event: 'login',
            method: 'log_pass'
         })
         window.location.href =
            window.location.pathname + (window.location.search ? window.location.search + '&action=signin' : '?action=signin')
      })
      .catch((error: unknown) => {
         handleApolloError(error)
      })
}

function getCodeForSignUp() {
   if (!validateForm(true)) return

   mutateGetCodeToEmail({
      email: email.value!,
      unique: true,
      locale: locale.value as UserLang
   })
      .then(() => {
         status.value = 'optRegistration'
      })
      .catch((error: unknown) => {
         handleApolloError(error)
      })
}

async function onSignUp(code: string, clear: () => void) {
   mutateSignUp({
      code,
      email: email.value!,
      password: password.value!,
      locale: locale.value as UserLang,
      timezone: getUtc()
   })
      .then(() => {
         gtm?.trackEvent({
            event: 'signup',
            method: 'log_pass'
         })

         window.location.href =
            window.location.pathname +
            (window.location.search ? window.location.search + '&next=sub&action=signup' : '?next=sub&action=signup')
      })
      .catch((error: unknown) => {
         handleApolloError(error)
         clear()
      })
}

function getCodeForResetPassword() {
   if (!validateForm(true)) return

   mutateGetCodeToEmail({
      email: email.value!,
      exists: true,
      locale: locale.value as UserLang
   })
      .then(() => {
         status.value = 'otpResetPassword'
      })
      .catch((error: unknown) => {
         handleApolloError(error)
      })
}

function onResetPassword(code: string, clear: () => void) {
   mutateResetPassword({
      code,
      email: email.value!,
      password: password.value!
   })
      .then(() => {
         status.value = 'auth'
         signInIsActive.value = true
      })
      .catch((error: unknown) => {
         handleApolloError(error)
         clear()
      })
}

watch(status, () => {
   setDefaultError()
})
</script>

<template>
   <Modal v-model="isActive" @unlock="modals.clear()">
      <div
         class="bg-black-850 overflow-hidden rounded-10px p-36px w-456px mx-auto <tablet:w-full <tablet:p-4px m-medium flex flex-col border border-gray-50/4 relative z-1"
      >
         <div class="h-40px bg-black-900 rounded-10px p-4px grid grid-cols-2 m-medium relative z-1 select-none mb-22px">
            <button
               :class="signInIsActive ? 'text-gray-50 cursor-default' : 'text-gray-800 &hover:text-orange-900 trs'"
               @click="onChangeTab(true)"
            >
               {{ $t('auth.login.loginToggle') }}
            </button>
            <button
               :class="!signInIsActive ? 'text-gray-50 cursor-default' : 'text-gray-800 &hover:text-orange-900 trs'"
               @click="onChangeTab(false)"
            >
               {{ $t('auth.registration.loginToggle') }}
            </button>

            <div
               aria-hidden="true"
               class="absolute -z-1 left-4px top-4px bottom-4px w-[calc(50%-4px)] gradient-selected-800 trs"
               :class="signInIsActive ? 'translate-x-0 rounded-l-8px' : 'translate-x-full rounded-r-8px'"
            />
         </div>

         <div v-if="errorText" class="flex items-center justify-start <tablet:justify-center gap-x-8px text-red-700 s-medium mb-22px">
            <i class="i-custom-warning-solid text-14px flex-shrink-0"></i>
            {{ errorText }}
         </div>

         <template v-if="status === 'auth'">
            <div v-if="signInIsActive" class="flex flex-col gap-y-24px">
               <h2 class="h3-semibold <tablet:text-center">{{ $t('auth.login.title') }}</h2>

               <div class="flex flex-col gap-y-12px">
                  <Input
                     v-model="email"
                     :placeholder="$t('auth.login.emailPlaceholder')"
                     icon="i-custom-email-outline"
                     :error="errorInput.email"
                     size="small"
                     class="bg-opacity-80"
                     selected-focus
                     @keydown.enter="onSignIn"
                  />
                  <Input
                     v-model="password"
                     :placeholder="$t('auth.login.passPlaceholder')"
                     icon="i-custom-password-outline"
                     :error="errorInput.password"
                     password
                     size="small"
                     class="bg-opacity-80"
                     @keydown.enter="onSignIn"
                  />
               </div>

               <button class="s-semibold &hover:text-orange-900 trs self-center" @click="status = 'resetPassword'">
                  {{ $t('auth.login.forgot') }}
               </button>

               <div class="flex flex-col gap-y-12px">
                  <Button class="p-14px m-semibold" :load="loadingSignIn" @click="onSignIn">{{ $t('auth.login.button') }}</Button>
                  <ButtonSocial social="google" :href="googleSignIn">{{ $t('auth.login.google') }}</ButtonSocial>
               </div>
            </div>
            <div v-else class="flex flex-col gap-y-24px">
               <h2 class="h3-semibold <tablet:text-center">{{ $t('auth.registration.title') }}</h2>

               <div class="flex flex-col gap-y-12px">
                  <Input
                     v-model="email"
                     :placeholder="$t('auth.login.emailPlaceholder')"
                     icon="i-custom-email-outline"
                     :error="errorInput.email"
                     size="small"
                     class="bg-opacity-80"
                     selected-focus
                     @keydown.enter="getCodeForSignUp"
                  />
                  <Input
                     v-model="password"
                     :placeholder="$t('auth.login.passPlaceholder')"
                     icon="i-custom-password-outline"
                     :error="errorInput.password"
                     password
                     size="small"
                     class="bg-opacity-80"
                     @keydown.enter="getCodeForSignUp"
                  />
                  <Input
                     v-model="passwordRepeat"
                     :placeholder="$t('auth.registration.passRepetePlaceholder')"
                     icon="i-custom-password-outline"
                     :error="errorInput.passwordRepeat"
                     password
                     size="small"
                     class="bg-opacity-80"
                     @keydown.enter="getCodeForSignUp"
                  />
               </div>

               <p class="s-medium text-center text-gray-800">
                  {{ $t('MvLhHbnjms0k1wwDGItmO') }}
                  <a
                     :href="locale === 'ru' ? '/docs/terms-of-use.pdf' : '/docs/terms-and-conditions-en.pdf'"
                     target="_blank"
                     class="text-gray-50 &hover:text-orange-900 trs"
                     >{{ $t('vACuDtPtxBxlltwhlgKij') }}</a
                  >
                  {{ $t('LAx1P91cqQPNqDydPlYvi') }}
                  <a
                     :href="locale === 'ru' ? '/docs/privacy-policy.pdf' : '/docs/privacy-policy-en.pdf'"
                     target="_blank"
                     class="text-gray-50 &hover:text-orange-900 trs"
                     >{{ $t('hCOA76oz7W7A7P9aHz6k4') }}</a
                  >
               </p>

               <div class="flex flex-col gap-y-12px">
                  <Button class="p-14px m-semibold" :load="loadingGetCodeToEmail" @click="getCodeForSignUp">{{
                     $t('auth.registration.button')
                  }}</Button>
                  <ButtonSocial social="google" :href="googleSignUp">{{ $t('auth.registration.google') }}</ButtonSocial>
               </div>
            </div>
         </template>

         <OTP
            v-else-if="status === 'optRegistration'"
            button-text="Зарегистрироваться"
            :loading="loadingSignUp"
            @submit="onSignUp"
            @back="status = 'auth'"
         />

         <div v-else-if="status === 'resetPassword'" class="flex flex-col gap-y-16px">
            <button class="flex items-center gap-x-8px s-semibold text-yellow-700 &hover:text-orange-900 trs" @click="status = 'auth'">
               <i class="i-custom-long-arrow-outline text-14px rotate-180deg"></i>
               {{ $t('-4cfiqJ5ECv5IdMiWLJ8j') }}
            </button>
            <div class="flex flex-col gap-y-24px">
               <h2 class="h3-semibold">{{ $t('auth.passReset.step1.resetTitle') }}</h2>
               <Input
                  v-model="email"
                  :placeholder="$t('qw9hPnbppFZMvKOky3bOK')"
                  icon="i-custom-email-outline"
                  :error="errorInput.email"
                  size="small"
                  class="bg-opacity-80"
                  @keydown.enter="getCodeForResetPassword"
               />
               <Input
                  v-model="password"
                  :placeholder="$t('auth.passReset.step2.passPlaceholder')"
                  icon="i-custom-password-outline"
                  :error="errorInput.password"
                  password
                  size="small"
                  class="bg-opacity-80"
                  @keydown.enter="getCodeForResetPassword"
               />
               <Input
                  v-model="passwordRepeat"
                  :placeholder="$t('auth.registration.passRepetePlaceholder')"
                  icon="i-custom-password-outline"
                  :error="errorInput.passwordRepeat"
                  password
                  size="small"
                  class="bg-opacity-80"
                  @keydown.enter="getCodeForResetPassword"
               />
               <Button class="p-14px m-semibold" :load="loadingResetPassword" @click="getCodeForResetPassword">{{
                  $t('auth.passReset.step1.button')
               }}</Button>
            </div>
         </div>

         <OTP
            v-else-if="status === 'otpResetPassword'"
            :button-text="$t('auth.passReset.step2.button')"
            @back="status = 'auth'"
            @submit="onResetPassword"
         />
      </div>
   </Modal>
</template>
