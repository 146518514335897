import { ref } from 'vue'
import { defineStore } from 'pinia'
import type { NotificationsData, NotificationsMessage } from '@/types/notifications'

export const useGlobalStore = defineStore('global', () => {
   const notificationCount = ref(0)
   const onboarding = ref(false)
   const scheduleMobileIsActive = ref(false)
   const screenLock = ref(false)
   const menuFromBurgerIsActive = ref(false)
   const menuFromBurger = ['user', 'subscription', 'history', 'matches', 'education', 'about', 'user']
   const promocodeApplied = ref<string>()

   const notifications = ref<Array<NotificationsData>>([])
   function notificationAdd(message: NotificationsMessage, duration = 5000) {
      notificationCount.value++
      const id = notificationCount.value
      notifications.value.push({
         id,
         ...message,
         timer: setTimeout(() => {
            notifications.value = notifications.value.filter((item) => item.id !== id)
         }, duration)
      })
   }

   const changeScreenLock = (status: boolean) => {
      screenLock.value = status
   }
   const changeScheduleMobileStatus = (status: boolean) => {
      scheduleMobileIsActive.value = status
   }

   return {
      onboarding,
      scheduleMobileIsActive,
      screenLock,
      menuFromBurgerIsActive,
      menuFromBurger,
      changeScreenLock,
      changeScheduleMobileStatus,
      notificationAdd,
      notifications,
      promocodeApplied
   }
})
