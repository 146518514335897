<script setup lang="ts">
import { ref, onMounted, computed } from 'vue'
import TeamLogo from '@/components/TeamLogo.vue'

const props = defineProps<{
   hasSubscription: boolean
   teamName: string
   logo?: string | null
   logoX1?: string | null
   logoX2?: string | null
   isTeamLeft: boolean
   chance?: number
   isHigherChance?: boolean
   odd?: number
   isHigherOdd?: boolean
   finalChance: boolean
   hasPredict: boolean
   hasWinner?: boolean
   isWinner?: boolean
   isPromo?: boolean
   isGray?: boolean
}>()

const refTeamName = ref<HTMLElement>()

function resizeTextToContainer() {
   if (refTeamName.value) {
      const parentElement = refTeamName.value.parentElement

      if (!parentElement) {
         return
      }

      if (refTeamName.value.clientHeight > parentElement.clientHeight) {
         refTeamName.value.style.fontSize = '12px'
         refTeamName.value.style.lineHeight = '100%'
      } else {
         refTeamName.value.style.fontSize = '14px'
         refTeamName.value.style.lineHeight = '140%'
      }
   }
}

onMounted(() => {
   resizeTextToContainer()
})

const statusChance = computed<'waiting' | 'hidden' | 'visible'>(() => {
   if (!props.hasSubscription && !props.hasWinner && !props.isPromo) {
      return 'hidden'
   }

   if (!props.hasPredict) {
      return 'waiting'
   }

   if (props.chance) {
      return 'visible'
   }

   return 'hidden'
})

const bgColor = computed(() => {
   if (props.hasWinner && props.isWinner) {
      return 'rgba(44, 194, 86, 0.16)'
   }

   return 'rgba(65, 62, 69, 0.3)'
})
</script>

<template>
   <section
      class="h-78px rounded-10px relative z-1 flex items-center justify-between gap-x-4px overflow-hidden"
      :class="[hasWinner ? (isTeamLeft ? 'pr-16px' : 'pl-16px') : isTeamLeft ? 'pr-12px' : 'pl-12px', isTeamLeft ? '' : 'flex-row-reverse']"
   >
      <div class="flex-auto flex flex-col items-center gap-y-2px" :class="hasWinner ? (isWinner ? '' : 'opacity-40') : ''">
         <div class="w-40px h-40px p-2px">
            <TeamLogo :logo="logo" :logo-x1="logoX1" :logo-x2="logoX2" :class="{ 'grayscale opacity-30': isGray }" />
         </div>
         <div class="h-20px">
            <div ref="refTeamName" class="text-center line-clamp-2 font-500 break-anywhere" :class="{ 'text-gray-500': isGray }">
               {{ teamName }}
            </div>
         </div>
      </div>

      <div
         class="flex-shrink-0 w-44px flex flex-col gap-y-6px"
         :class="[isTeamLeft ? 'items-end ' : 'items-start', hasWinner ? (isWinner ? '' : 'opacity-40') : '']"
      >
         <span
            v-if="statusChance === 'visible'"
            class="h3-semibold"
            :class="finalChance && isHigherChance !== undefined ? (isHigherChance ? 'text-green-500' : 'text-red-700') : ''"
         >
            {{ chance }}%
         </span>
         <div v-else-if="statusChance === 'waiting'" class="py-2px">
            <i class="i-custom-sandclock-solid text-20px opacity-50 block"></i>
         </div>
         <div v-else class="py-2px">
            <i class="i-custom-lock-solid text-20px opacity-50 block"></i>
         </div>

         <div v-if="odd" class="flex items-center justify-center gap-x-4px">
            <span class="s-medium" :class="{ 'text-gray-500': isGray }">{{ odd.toFixed(2) }}</span>
            <span
               class="i-custom-coef text-10px"
               :class="
                  isHigherOdd
                     ? isGray
                        ? 'text-gray-600'
                        : 'text-green-500'
                     : isGray
                       ? 'text-gray-800 rotate-180'
                       : 'text-red-700 rotate-180'
               "
            />
         </div>
         <div v-else class="h-12px flex items-center"><div class="bg-gray-800 w-16px h-2px"></div></div>
      </div>

      <div
         aria-hidden="true"
         class="team-card-match-bg absolute inset-0 -z-1 pointer-events-none overflow-hidden"
         :class="isTeamLeft ? '' : '-scale-x-100'"
      >
         <i class="i-custom-triangle-solid text-118px absolute -left-76px bottom-6px bg-gray-800/10 opacity-80"></i>
         <i class="i-custom-triangle-solid text-64px absolute left-4px -top-40px rotate-180 bg-gray-800/10 opacity-80"></i>

         <div
            v-if="props.hasWinner && props.isWinner"
            class="h-2px bg-gradient-to-r from-green-500/0 to-green-500 rounded-full absolute bottom-0 left-16px right-16px"
         ></div>
      </div>
   </section>
</template>

<style scoped>
.team-card-match-bg {
   --bg-color: v-bind(bgColor);
   background:
      radial-gradient(100% 80% at 100% 100%, var(--bg-color) 0%, rgba(0, 0, 0, 0) 100%),
      linear-gradient(90deg, rgba(41, 42, 51, 0) 0%, #292a33 100%);
}
</style>
