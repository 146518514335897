<script setup lang="ts">
import { useRouter } from 'vue-router'
import { i18nRoute } from '@/i18n'
import { gainAccess } from '@/composables'

defineProps<{
   hasSubscription: boolean
   matchId: number
}>()

const router = useRouter()

const handleClick = (text: string) => {
   gainAccess(router, true, text)
}
</script>

<template>
   <section class="py-4px px-8px items-center gap-x-8px s-semibold grid grid-cols-2">
      <router-link
         :to="i18nRoute({ name: 'match', params: { id: matchId } })"
         class="h-36px flexc rounded-8px backdrop-blur-25 trs"
         :class="
            hasSubscription
               ? 'bg-yellow-700/10 text-yellow-700 &hover:bg-orange-900/10 &hover:text-orange-900 col-span-full'
               : 'bg-gray-50/10 &hover:bg-gray-50/5'
         "
      >
         {{ $t('kTgvzl59jeD4XLizCJkRX') }}
      </router-link>
      <button
         v-if="!hasSubscription"
         class="h-36px flexc rounded-8px backdrop-blur-25 bg-yellow-700/10 text-yellow-700 &hover:bg-orange-900/10 &hover:text-orange-900 trs"
         @click="handleClick($t('bikDwqezYQOgqTUnEPzon'))"
      >
         {{ $t('bikDwqezYQOgqTUnEPzon') }}
      </button>
   </section>
</template>

<style scoped></style>
