<script setup lang="ts">
import { ref } from 'vue'
import Input from '@/components/UI/Input.vue'
withDefaults(
   defineProps<{
      modelValue: string | number | undefined
      inputType?: string
      placeholder?: string
      size?: 'small' | 'medium' | 'large'
      icon?: string
      error?: boolean
      password?: boolean
      errorText?: string
      selectedFocus?: boolean
   }>(),
   {
      inputType: 'text',
      placeholder: undefined,
      size: 'medium',
      icon: undefined,
      error: false,
      password: false,
      errorText: undefined,
      selectedFocus: false
   }
)

const emit = defineEmits<{
   (event: 'update:modelValue', value: string | number | undefined): void
}>()

const showPassword = ref(false)
</script>

<template>
   <label
      class="bg-black-800 border rounded-10px flex items-center gap-x-12px cursor-text relative"
      :class="[
         { 'px-20px py-12px s-medium2': size === 'small' },
         { 'px-20px py-16px m-medium': size === 'medium' },
         error || errorText ? 'border-red-700' : 'border-gray-800'
      ]"
   >
      <i v-if="icon" :class="[size === 'small' ? 'text-14px' : 'text-16px', icon]"></i>
      <Input
         :model-value="modelValue"
         :placeholder="placeholder"
         :input-type="password && !showPassword ? 'password' : inputType"
         :selected-focus="selectedFocus"
         @update:model-value="emit('update:modelValue', $event)"
      />
      <div
         v-if="password"
         class="&hover:text-orange-900 trs"
         :class="[size === 'small' ? 'text-14px' : 'text-16px', showPassword ? 'i-custom-eye-crossed-outline' : 'i-custom-eye-outline']"
         @click="showPassword = !showPassword"
      />
      <slot />

      <div v-if="errorText" class="absolute top-full left-0 text-10px text-red-700">
         {{ errorText }}
      </div>
   </label>
</template>

<style scoped></style>
