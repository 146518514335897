import { ApolloError } from '@apollo/client/errors'
import * as Sentry from '@sentry/vue'

export const getKeyFromBackendError = (key: unknown) => {
   switch (key) {
      case 'CARD_NOT_FOUND': {
         return 'errors.backend.CARD_NOT_FOUND'
      }
      case 'INTERNAL': {
         return 'errors.backend.INTERNAL'
      }
      case 'INVALID_PASSWORD': {
         return 'errors.backend.INVALID_PASSWORD'
      }
      case 'SEND_EMAIL_TIMEOUT': {
         return 'errors.backend.SEND_EMAIL_TIMEOUT'
      }
      case 'USER_ALREADY_EXISTS': {
         return 'errors.backend.USER_ALREADY_EXISTS'
      }
      case 'USER_NOT_FOUND': {
         return 'errors.backend.USER_NOT_FOUND'
      }
      case 'WRONG_VERIFICATION_CODE': {
         return 'errors.backend.WRONG_VERIFICATION_CODE'
      }
      case 'TOO_MANY_ATTEMPTS': {
         return 'errors.backend.TOO_MANY_ATTEMPTS'
      }

      case 'USER_WAS_DELETED': {
         return 'errors.backend.USER_WAS_DELETED'
      }

      default: {
         return 'errors.backend.UNKNOWN'
      }
   }
}

export function errorApolloHandler(error: unknown) {
   let errorCode: undefined | string
   let errorMessage: undefined | string

   if (error instanceof ApolloError) {
      if (error.graphQLErrors && error.graphQLErrors.length > 0) {
         const firstError = error.graphQLErrors[0]
         errorCode = (firstError.extensions?.code as string) ?? 'Unknown code'
         errorMessage = firstError.extensions ? (firstError.extensions.message as string) : firstError.message
      }
   } else if (error instanceof Error) {
      errorCode = error.name
      errorMessage = error.message
   } else {
      errorCode = 'Unknown code'
      errorMessage = 'Unknown message'
   }

   Sentry.captureException(error)

   return { errorCode, errorMessage }
}
