<script setup lang="ts">
import { ref, watch, useTemplateRef } from 'vue'
import { useDebounceFn } from '@vueuse/core'

const props = withDefaults(
   defineProps<{
      modelValue: number | string
      min?: number
      max?: number
      maxLength?: number
      decimalPlaces?: number
      selectFocus?: boolean
      disabled?: boolean
      debounce?: number
   }>(),
   {
      decimalPlaces: 10,
      debounce: 0
   }
)

const inputRef = useTemplateRef('input')

const emit = defineEmits<{
   'update:modelValue': [number | '']
   'update:focus': [boolean]
}>()

const inputValue = ref(props.modelValue)

watch(
   () => props.modelValue,
   () => {
      if (props.modelValue === inputValue.value) return
      inputValue.value = props.modelValue
   }
)

const handler = useDebounceFn(async (event: Event) => {
   if (event instanceof InputEvent) {
      const element = event.target as HTMLInputElement

      let value = element.value

      if (event.inputType === 'insertFromPaste' && Number.isNaN(Number(value))) {
         inputValue.value = ''
         return
      }

      const decimalPattern = props.decimalPlaces && props.decimalPlaces !== 0 ? `([,.бю]?\\d{0,${props.decimalPlaces}})?` : ''
      const regexPattern = props.min !== undefined && props.min >= 0 ? `^\\d+${decimalPattern}$` : `^-?$|^-?\\d+${decimalPattern}$`
      const regex = new RegExp(regexPattern, 'g')

      if (!regex.test(value) || (props.maxLength && value.length > props.maxLength)) {
         const start = element.selectionStart ?? 0
         const end = element.selectionEnd ?? 0

         value = value.slice(0, start - 1) + value.slice(end)
      }

      inputValue.value = value.replace(/[,бю]/, '.')

      if (props.max !== undefined && Number(value) > props.max) {
         inputValue.value = props.max
      }

      if (inputValue.value === '-') {
         return
      }

      emit('update:modelValue', inputValue.value ? Number(inputValue.value) : '')
   }
}, props.debounce)

function change() {
   if (props.min !== undefined && (inputValue.value === '' || Number(inputValue.value) < props.min)) {
      inputValue.value = props.min
      emit('update:modelValue', props.min)
   } else if (inputValue.value === '-') {
      inputValue.value = ''
      emit('update:modelValue', '')
   }
}

function focus() {
   if (props.selectFocus) {
      inputRef.value?.select()
   }

   emit('update:focus', true)
}

function select() {
   inputRef.value?.select()
}

defineExpose({
   focus,
   select
})
</script>

<template>
   <input
      ref="input"
      v-model="inputValue"
      type="text"
      inputmode="decimal"
      :disabled="disabled"
      class="outline-none w-full h-full bg-transparent"
      @input="handler"
      @change="change"
      @blur="emit('update:focus', false)"
      @focus="focus"
      @select="select"
   />
</template>

<style scoped></style>
