import { computed, reactive, ref } from 'vue'
import { locale } from '@/i18n'

export const promocode = ref('')
export const promocodeIsValid = ref(false)

export const discount = reactive<{
   amount: number
   isPercent: boolean
   recurrent: boolean
   for_ids: number[]
}>({
   amount: 0,
   isPercent: true,
   recurrent: true,
   for_ids: []
})

export type TariffDetails = {
   price: number
   discount: number
   months: number
   planId: number
}

export type TariffPlan = {
   price: number
   priceFull: number
   planId: number
   months: Record<number, TariffDetails>
}

export type Tariffs = {
   pro: TariffPlan
   insider: TariffPlan
}

const calculateDiscountedPrice = (basePrice: number, tariffId: number, isOneTime: boolean = true) => {
   if (
      !promocodeIsValid.value ||
      (!discount.recurrent && !isOneTime) ||
      (discount.recurrent && isOneTime) ||
      (discount.for_ids.length > 0 && !discount.for_ids.includes(tariffId))
   ) {
      return basePrice
   }

   return discount.isPercent ? basePrice * ((100 - discount.amount) / 100) : basePrice - discount.amount
}

export const tariffs = computed<Tariffs>(() => {
   const basePrices = {
      ru: {
         pro: 9990,
         insider: 9990,
         proMonths: {
            3: 1390,
            5: 1690,
            7: 1990
         },
         insiderMonths: {
            3: 1390,
            5: 1690,
            7: 1990
         }
      },
      default: {
         pro: 99,
         insider: 99,
         proMonths: {
            3: 13,
            5: 16,
            7: 19
         },
         insiderMonths: {
            3: 13,
            5: 16,
            7: 19
         }
      }
   }

   const prices = locale.value === 'ru' ? basePrices.ru : basePrices.default

   return {
      pro: {
         planId: 201,
         price: calculateDiscountedPrice(prices.pro, 201, false),
         priceFull: prices.pro,
         months: {
            3: {
               planId: 202,
               price: calculateDiscountedPrice(prices.insiderMonths[3], 202),
               discount: 0,
               months: 3
            },
            5: {
               planId: 203,
               price: calculateDiscountedPrice(prices.insiderMonths[5], 203),
               discount: 0,
               months: 5
            },
            7: {
               planId: 204,
               price: calculateDiscountedPrice(prices.insiderMonths[7], 204),
               discount: 0,
               months: 7
            }
         }
      },
      insider: {
         planId: 201,
         price: calculateDiscountedPrice(prices.insider, 201, false),
         priceFull: prices.insider,
         months: {
            3: {
               planId: 202,
               price: calculateDiscountedPrice(prices.insiderMonths[3], 202),
               discount: 0,
               months: 3
            },
            5: {
               planId: 203,
               price: calculateDiscountedPrice(prices.insiderMonths[5], 203),
               discount: 0,
               months: 5
            },
            7: {
               planId: 204,
               price: calculateDiscountedPrice(prices.insiderMonths[7], 204),
               discount: 0,
               months: 7
            }
         }
      }
   }
})

const apiTariffs = {
   recurrent: {
      id: 201,
      trialId: 200,
      trialPeriod: 3,
      trialAmount: {
         rub: 990,
         usd: 9,
         eur: 9
      },
      amount: {
         rub: 9990,
         usd: 99,
         eur: 99
      }
   },
   oneTime: [
      {
         id: 202,
         period: 3,
         amount: {
            rub: 1390,
            usd: 13,
            eur: 13
         }
      },
      {
         id: 203,
         period: 5,
         amount: {
            rub: 1690,
            usd: 16,
            eur: 16
         }
      },
      {
         id: 204,
         period: 7,
         amount: {
            rub: 1990,
            usd: 19,
            eur: 19
         }
      }
   ]
}

export const selectedIndexDay = ref(0)
export const selectedCurrency = ref<'RUB' | 'EUR'>('RUB')

export const insiderOneDays = computed(() => {
   const days = apiTariffs.oneTime || [{ period: 0 }]
   return days.map((item) => item.period)
})

export const amountTariffs = computed(() => {
   const tariffs = apiTariffs

   if (!tariffs) {
      return {
         recurrent: {
            id: 0,
            trialId: 0,
            trialPeriod: 0,
            trialAmount: 0,
            amount: 0
         },
         oneTime: {
            id: 0,
            amount: 0,
            period: 0
         }
      }
   }

   const amountLocale = selectedCurrency.value === 'RUB' ? 'rub' : 'usd'

   return {
      recurrent: {
         id: tariffs.recurrent.id,
         trialId: tariffs.recurrent.trialId,
         trialPeriod: tariffs.recurrent.trialPeriod,
         trialAmount: tariffs.recurrent.trialAmount[amountLocale],
         amount: tariffs.recurrent.amount[amountLocale]
      },
      oneTime: {
         id: tariffs.oneTime[selectedIndexDay.value].id,
         amount: tariffs.oneTime[selectedIndexDay.value].amount[amountLocale],
         period: tariffs.oneTime[selectedIndexDay.value].period
      }
   }
})

const currencyFormatter = computed(() => {
   return new Intl.NumberFormat(locale.value, {
      style: 'currency',
      currency: selectedCurrency.value,
      minimumFractionDigits: 0,
      maximumFractionDigits: 2
   })
})

export function formatCurrency(amount: number) {
   return currencyFormatter.value.format(amount)
}

export const setCurrency = (currency: typeof selectedCurrency.value) => {
   selectedCurrency.value = currency
   if (window?.localStorage) {
      window.localStorage.setItem('currency', currency)
   }
}

export const initCurrency = () => {
   const storedCurrency = window?.localStorage?.getItem('currency') as typeof selectedCurrency.value | undefined
   if (storedCurrency) {
      selectedCurrency.value = storedCurrency
   } else {
      setCurrency(locale.value === 'ru' ? 'RUB' : 'EUR')
   }
}
