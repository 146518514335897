<script setup lang="ts">
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { useElementHover } from '@vueuse/core'
import { sendEvent } from '@/composables/gtm'
import { modals } from '@/components/UI/Modal'

const router = useRouter()

const handler = (text: string) => {
   sendEvent('cta', text, '5steps_analytics', undefined, router)
   modals.Auth(false)
}

const hoverElementSvyat = ref()
const hoverElementAkimov = ref()
const isHoveredSvyat = useElementHover(hoverElementSvyat)
const isHoveredAkimov = useElementHover(hoverElementAkimov)
</script>

<template>
   <div
      class="flex <tablethd:flex-col <tablethd:items-center gap-x-60px <laptop:gap-x-24px <laptop:justify-between pl-110px <fullhd:px-46px <tablethd:px-4px"
   >
      <div class="flex-shrink-0 flex <tablethd:flex-col <tablethd:w-368px items-start tablethd:-mt-28px <tablethd:mb-24px">
         <div class="bg-player" :class="isHoveredAkimov ? 'opacity-0 z-1' : 'z-2'">
            <div ref="hoverElementSvyat" class="for-hover"></div>
            <div
               class="absolute bottom-8px tablethd:-left-10px <tablethd:-right-10px h2-semibold <tablethd:h3-semibold flex flex-col tablethd:items-end <tablethd:gap-y-4px z-1 transition duration-500 ease-in-out"
               :class="isHoveredSvyat ? 'opacity-0 -translate-x-20px <tablethd:translate-x-20px' : ''"
            >
               <span>svyat</span>
               <div class="flex gap-x-8px">
                  <span class="w-4px h-4px rounded-full bg-yellow-700"></span>
                  <span class="w-4px h-4px rounded-full bg-yellow-700"></span>
                  <span class="w-4px h-4px rounded-full bg-yellow-700"></span>
               </div>
            </div>
            <img
               src="/img/what-you-get/analytics/svyat.png"
               srcset="/img/what-you-get/analytics/svyat@2.png 2x"
               alt="svyat"
               class="w-261px h-271px <tablethd:w-181px <tablethd:h-188px transition-transform duration-300 ease-in-out <tablethd:-scale-x-100 <tablethd:-translate-x-14px"
               :class="isHoveredSvyat ? 'tablethd:translate-x-0' : 'translate-x-26px'"
            />

            <div
               class="flex flex-col gap-y-4px w-230px <tablet:w-182px absolute top-28px <tablethd:top-18px left-[calc(100%-14px)] <tablethd:left-[calc(100%-28px)] duration-500 pointer-events-none"
               :class="isHoveredSvyat ? 'opacity-100' : 'opacity-0 translate-x-8px'"
            >
               <span class="h2-semibold">svyat</span>
               <p class="s-medium2">
                  {{ $t('mUPAhvkdlVXIKsqQ-mp0E') }}
               </p>
            </div>
         </div>
         <div
            class="bg-player mt-77px tablethd:-ml-20px <tablethd:-mt-120px <tablethd:self-end"
            :class="isHoveredSvyat ? 'opacity-0 z-1' : 'z-2'"
         >
            <div ref="hoverElementAkimov" class="for-hover"></div>
            <div
               class="absolute bottom-8px -left-10px h2-semibold <tablethd:h3-semibold flex flex-col <tablethd:gap-y-4px items-end z-1 transition duration-500 ease-in-out"
               :class="isHoveredAkimov ? 'opacity-0 -translate-x-20px' : ''"
            >
               <span>AKIMOV</span>
               <div class="flex gap-x-8px">
                  <span class="w-4px h-4px rounded-full bg-yellow-700"></span>
                  <span class="w-4px h-4px rounded-full bg-yellow-700"></span>
                  <span class="w-4px h-4px rounded-full bg-yellow-700"></span>
               </div>
            </div>
            <img
               src="/img/what-you-get/analytics/akimov.png"
               srcset="/img/what-you-get/analytics/akimov@2.png 2x"
               alt="akimov"
               class="w-165px h-269px <tablethd:w-111px <tablethd:h-182px transition-transform duration-300 ease-in-out <tablethd:translate-x-54px"
               :class="isHoveredAkimov ? 'tablethd:translate-x-50px' : 'translate-x-80px'"
            />
            <div
               class="flex flex-col gap-y-4px w-230px <tablet:w-182px absolute top-28px <tablethd:-top-52px right-[calc(100%-16px)] <tablethd:right-[calc(100%-28px)] duration-500 pointer-events-none"
               :class="isHoveredAkimov ? 'opacity-100' : 'opacity-0 -translate-x-8px'"
            >
               <span class="h2-semibold">AKIMOV</span>
               <p class="s-medium2">
                  {{ $t('GbQvVsgRf0-4-C3MoTn9z') }}
               </p>
            </div>
         </div>
      </div>

      <div class="<tablethd:mb-24px <tablethd:px-8px">
         <header class="relative flex items-center gap-x-16px mb-24px <tablethd:hidden">
            <div class="w-2px h-22px rounded-10px bg-yellow-700 shadow-yellow"></div>
            <h2>{{ $t('8mKupwNVl6TzQMaV8ZV6V') }}</h2>
         </header>
         <div class="l-medium tablethd:ml-18px <tablet:m-medium">
            <div class="l-semibold mb-24px <tablethd:mb-16px">
               AKIMOV {{ $t('LAx1P91cqQPNqDydPlYvi') }} svyat — <span class="text-yellow-700">{{ $t('hASnwn6SZZ-3BBGeD4Q38') }}</span>
            </div>
            {{ $t('bX3BG2Dny73tDAMTvDyD1') }}
            <ul class="m-semibold <tablet:s-medium2 mt-16px <tablethd:mt-20px flex flex-col gap-y-8px <tablethd:gap-y-4px">
               <li class="flex items-center gap-x-12px">
                  <div class="flex-shrink-0 w-36px aspect-square rounded-10px bg-gray-50/4 border border-gray-50/2 flexc backdrop-blur-25">
                     <div class="drop-shadow-yellow"><i class="i-custom-muscle-outline text-20px text-yellow-700 block"></i></div>
                  </div>
                  {{ $t('l-HcRX-LYdz3XdEwvMP-k') }}
               </li>
               <li class="flex items-center gap-x-12px">
                  <div class="flex-shrink-0 w-36px aspect-square rounded-10px bg-gray-50/4 border border-gray-50/2 flexc backdrop-blur-25">
                     <div class="drop-shadow-yellow"><i class="i-custom-magnifier-outline text-20px text-yellow-700 block"></i></div>
                  </div>
                  {{ $t('HUK6oxyfLMU9ouTa5-qiC') }}
               </li>
               <li class="flex items-center gap-x-12px">
                  <div class="flex-shrink-0 w-36px aspect-square rounded-10px bg-gray-50/4 border border-gray-50/2 flexc backdrop-blur-25">
                     <div class="drop-shadow-yellow"><i class="i-custom-talk-outline text-20px text-yellow-700 block"></i></div>
                  </div>
                  {{ $t('uumF968jBGVez99dRqTCb') }}
               </li>
            </ul>
         </div>
      </div>

      <button
         class="block text-center w-304px py-16px btn-lava-700 tablet:hidden"
         @click="handler($t('subscription.profileWidget.subscriptionWidget.button.state1', {}, { locale: 'en' }))"
      >
         {{ $t('subscription.profileWidget.subscriptionWidget.button.state1') }}
      </button>
   </div>
</template>

<style scoped>
.bg-player {
   --uno: 'min-w-264px min-h-229px relative transition-opacity duration-300 ease-in-out';
}
.bg-player::after,
.for-hover {
   content: '';
   --uno: 'absolute left-0 bottom-0 w-264px h-229px';
   -webkit-clip-path: polygon(44% 0, 56% 0, 100% 89%, 94% 100%, 6% 100%, 0 89%);
   clip-path: polygon(44% 0, 56% 0, 100% 89%, 94% 100%, 6% 100%, 0 89%);
}

.bg-player::after {
   --uno: 'bg-black-900 -z-1';
}
.for-hover {
   z-index: 2;
}

@screen lt-tablethd {
   .bg-player {
      --uno: 'min-w-184px min-h-160px';
   }
   .bg-player::after,
   .for-hover {
      --uno: 'w-184px h-160px';
   }
}
</style>
