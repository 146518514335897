import { computed } from 'vue'
import { locale } from '@/i18n'
import { useGlobalStore } from '@/stores/global'
import { useMutation } from '@vue/apollo-composable'
import { addCard, payRecurrent, Method, createSubscriptionPayment } from '@/gql/requests'
import { errorApolloHandler } from '@/composables/errors'
import { goToLink } from '@/composables'
import { amountTariffs } from '@/composables/tariffs'

export function useAddCard() {
   const { mutate, loading, error } = useMutation(addCard)
   const globalStore = useGlobalStore()

   const addCardMutation = async () => {
      try {
         const result = await mutate({
            method: locale.value === 'ru' ? Method.Ukassa : Method.Stripe
         })

         setTimeout(function () {
            window.location.href = result?.data?.addCard.data ?? '/'
         }, 0)
      } catch (error) {
         globalStore.notificationAdd({
            type: 'error',
            text: error instanceof Error ? (error.message ?? 'Unknown error') : 'Unknown error'
         })
      }
   }

   return { addCardMutation, loading, error }
}

export function useCreatePayment() {
   const { mutate: mutateCreatePaymentOneTime, loading: loadingCreatePaymentOneTime } = useMutation(createSubscriptionPayment)
   const { mutate: mutateCreatePaymentRecurrent, loading: loadingCreatePaymentRecurrent } = useMutation(payRecurrent)
   const { mutate: mutateCreatePaymentTrial, loading: loadingCreatePaymentTrial } = useMutation(addCard)

   const globalStore = useGlobalStore()

   function oneTime() {
      mutateCreatePaymentOneTime({
         method: locale.value === 'ru' ? Method.Ukassa : Method.Stripe,
         planId: amountTariffs.value.oneTime.id
      })
         .then((result) => {
            const data = result?.data?.createSubscriptionPayment.data

            if (!data) {
               throw new Error('No data')
            }

            goToLink(data)
         })
         .catch((error) => {
            console.log(errorApolloHandler(error))
            globalStore.notificationAdd({
               type: 'error',
               text: 'Ошибка при оплате'
            })
         })

      return
   }

   function trial() {
      mutateCreatePaymentTrial({
         method: locale.value === 'ru' ? Method.Ukassa : Method.Stripe
      })
         .then((result) => {
            const data = result?.data?.addCard.data

            if (!data) {
               throw new Error('No data')
            }

            goToLink(data)
         })
         .catch((error) => {
            console.log(errorApolloHandler(error))
            globalStore.notificationAdd({
               type: 'error',
               text: 'Ошибка при оплате'
            })
         })
   }

   function recurrent() {
      mutateCreatePaymentRecurrent({
         planId: amountTariffs.value.recurrent.id,
         method: locale.value === 'ru' ? Method.Ukassa : Method.Stripe
      })
         .then((result) => {
            const data = result?.data?.createSubscriptionPayment.data

            if (!data) {
               throw new Error('No data')
            }

            goToLink(data)
         })
         .catch((error) => {
            console.log(errorApolloHandler(error))
            globalStore.notificationAdd({
               type: 'error',
               text: 'Ошибка при оплате'
            })
         })
   }

   return {
      oneTime,
      trial,
      recurrent,
      loading: computed(() => loadingCreatePaymentOneTime.value || loadingCreatePaymentRecurrent.value || loadingCreatePaymentTrial.value)
   }
}
