<script setup lang="ts">
import { computed } from 'vue'
import ClosedInfoCardMatch from './ClosedInfo.vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const props = defineProps<{
   hasSubscription: boolean
   matchEnded: boolean
   matchIsLive: boolean
   isPromo: boolean
   hasPredict: boolean
   level?: number
   teamName?: string
   chance?: number
   odd?: number
}>()

const levelRisk = computed<{
   level: number
   colorText: string
   colorBg: string
   text: string
}>(() => {
   if (props.level === 1) {
      return {
         level: 1,
         colorText: 'text-green-500',
         colorBg: 'bg-green-500',
         text: t('cardMatch.risk.title.level3')
      }
   }
   if (props.level === 2) {
      return {
         level: 2,
         colorText: 'text-yellow-700',
         colorBg: 'bg-yellow-700',
         text: t('cardMatch.risk.title.level5')
      }
   }
   if (props.level === 3) {
      return {
         level: 3,
         colorText: 'text-red-700',
         colorBg: 'bg-red-700',
         text: t('cardMatch.risk.title.level7')
      }
   }

   return {
      level: 0,
      colorText: 'text-gray-50',
      colorBg: 'bg-gray-800',
      text: t('cardMatch.risk.title.level0')
   }
})

const isRecommended = computed(() => props.level && props.level <= 2)
const descriptionStatus = computed(() => {
   if ((!props.hasSubscription && !props.isPromo) || (props.hasPredict && !props.chance)) {
      return 'noAccess'
   }

   if (!props.hasPredict) {
      return 'noPredict'
   }

   if (isRecommended.value) {
      if (props.chance! > 60) {
         return !props.odd || props.odd < 1.3 ? 'description2' : 'description1'
      }

      if (props.chance! >= 51) {
         return 'description4'
      }
   } else {
      if (props.chance! > 60) {
         return 'description3'
      }

      if (props.chance! >= 51) {
         return 'description5'
      }
   }

   if (props.chance! === 50) {
      return 'description6'
   }

   return 'noPredict'
})
</script>

<template>
   <section class="pt-12px px-8px flex flex-col gap-y-8px flex-auto" :class="matchEnded ? 'pb-12px' : 'pb-8px'">
      <div class="flex items-center justify-between gap-x-16px">
         <div class="flex items-center gap-x-6px s-semibold whitespace-nowrap">
            <i v-if="levelRisk.level" class="i-custom-risk-solid" :class="levelRisk.colorText"></i>
            <i v-else class="i-custom-unknownrisk-solid" :class="levelRisk.colorText"></i>
            {{ levelRisk.text }}
         </div>

         <div class="grid grid-cols-3 items-center gap-x-8px max-w-220px w-full <mac:max-w-180px <laptop:max-w-160px">
            <div v-for="i in levelRisk.level" :key="i" class="rounded-full h-4px" :class="levelRisk.colorBg"></div>
            <div v-for="i in 3 - levelRisk.level" :key="i" class="rounded-full h-4px bg-gray-800"></div>
         </div>
      </div>
      <div v-if="!matchEnded" class="s-medium2 text-gray-50/70 tablet:line-clamp-3 tablet:min-h-50px">
         <template v-if="descriptionStatus === 'noAccess'">
            {{ $t('cardMatch.risk.noAccess1') }}
            <ClosedInfoCardMatch class="w-52px h-16px" />
            {{ $t('cardMatch.risk.noAccess2') }}
            <ClosedInfoCardMatch class="w-32px h-16px" />
            {{ $t('cardMatch.risk.noAccess3') }}
            <ClosedInfoCardMatch class="w-32px h-16px" />
            <br />
            {{ t('cardMatch.risk.goodVariant') }} {{ t('cardMatch.risk.bet5') }}
         </template>

         <template v-else-if="descriptionStatus === 'noPredict'">{{ $t('cardMatch.risk.noChance') }}</template>

         <template v-else-if="matchIsLive">
            <template v-if="descriptionStatus === 'description1'">
               {{ t('cardMatch.risk.winner', { name: teamName, chance }) }}, {{ t('cardMatch.risk.coef', { odd }) }} <br />
               {{ t('cardMatch.risk.goodVariant') }} <br />
               {{ t('cardMatch.risk.bet5') }}
            </template>

            <template v-if="descriptionStatus === 'description2'">
               {{ t('cardMatch.risk.winner', { name: teamName, chance }) }}. <br />
               {{ t('cardMatch.risk.coefHigh') }} <br />
               {{ t('cardMatch.risk.bet5') }}
            </template>

            <template v-if="descriptionStatus === 'description3'">
               {{ t('cardMatch.risk.winner', { name: teamName, chance }) }}, {{ t('cardMatch.risk.dataLittleLive') }}
               {{ t('cardMatch.risk.beCareful') }} <br />
               {{ t('cardMatch.risk.bet2') }}
            </template>

            <template v-if="descriptionStatus === 'description4'">
               {{ t('cardMatch.risk.winner', { name: teamName, chance }) }}, {{ t('cardMatch.risk.equalMatch') }} <br />
               {{ t('cardMatch.risk.considerTotals') }} <br />
               {{ t('cardMatch.risk.bet5') }}
            </template>

            <template v-if="descriptionStatus === 'description5'">
               {{ t('cardMatch.risk.winner', { name: teamName, chance }) }}, {{ t('cardMatch.risk.closeMatch') }} <br />
               {{ t('cardMatch.risk.studyForm') }} <br />
               {{ t('cardMatch.risk.bet2') }}
            </template>

            <template v-if="descriptionStatus === 'description6'">
               {{ t('cardMatch.risk.chance50') }} <br />
               {{ t('cardMatch.risk.bet2') }}
            </template>
         </template>
         <template v-else>
            <template v-if="descriptionStatus === 'description1'">
               {{ t('cardMatch.risk.winner', { name: teamName, chance }) }}, {{ t('cardMatch.risk.coef', { odd }) }}
               {{ t('cardMatch.risk.goodVariant') }} {{ t('cardMatch.risk.mostAccuratePrediction') }} {{ t('cardMatch.risk.bet5') }}
            </template>

            <template v-if="descriptionStatus === 'description2'">
               {{ t('cardMatch.risk.winner', { name: teamName, chance }) }}, {{ t('cardMatch.risk.coefSmall', { odd }) }}
               {{ t('cardMatch.risk.waitLive') }} {{ t('cardMatch.risk.bet5') }}
            </template>

            <template v-if="descriptionStatus === 'description3'">
               {{ t('cardMatch.risk.winner', { name: teamName, chance }) }}, {{ t('cardMatch.risk.dataLittle') }}
               {{ t('cardMatch.risk.waitLiveAndAnalytic') }} {{ t('cardMatch.risk.bet2') }}
            </template>

            <template v-if="descriptionStatus === 'description4'">
               {{ t('cardMatch.risk.winner', { name: teamName, chance }) }}, {{ t('cardMatch.risk.equalMatch') }}
               {{ t('cardMatch.risk.considerTotals') }} {{ t('cardMatch.risk.bet5') }}
            </template>

            <template v-if="descriptionStatus === 'description5'">
               {{ t('cardMatch.risk.winner', { name: teamName, chance }) }}, {{ t('cardMatch.risk.closeMatch') }}
               {{ t('cardMatch.risk.waitLiveAndAnalyze') }} {{ t('cardMatch.risk.bet2') }}
            </template>

            <template v-if="descriptionStatus === 'description6'">
               {{ t('cardMatch.risk.chance50') }} {{ t('cardMatch.risk.waitLiveAndAnalytic') }}
               {{ t('cardMatch.risk.bet2') }}
            </template>
         </template>
      </div>
   </section>
</template>

<style scoped></style>
