/* eslint-disable unicorn/better-regex */
import router from '@/router'

export const errorHandler = (text: string) => {
   router.push('/error?text=' + text)
}

export const validateEmail = (email: string) => {
   const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
   return re.test(String(email).toLowerCase())
}
