<script setup lang="ts">
import { ref, onMounted, computed } from 'vue'

const props = withDefaults(
   defineProps<{
      size: number
      lineWidth: number
      percentage: number
      margin?: number
      drawTime?: number
      color?: {
         top: string
         bottom: string
      }
      gradient?: boolean
      gradientPosition?: {
         top: {
            x1: number
            y1: number
            x2: number
            y2: number
         }
         bottom: {
            x1: number
            y1: number
            x2: number
            y2: number
         }
      }
      gradientColor?: {
         top: {
            start: string
            center?: string
            end: string
         }
         bottom: {
            start: string
            center?: string
            end: string
         }
      }
   }>(),
   {
      drawTime: 3,
      margin: 0,
      gradientPosition: () => ({
         top: {
            x1: 0,
            y1: 50,
            x2: 100,
            y2: 50
         },
         bottom: {
            x1: 0,
            y1: 50,
            x2: 100,
            y2: 50
         }
      }),
      gradientColor: () => ({
         top: {
            start: 'rgba(0, 0, 0, 1)',
            end: 'rgba(0, 0, 0, 1)'
         },
         bottom: {
            start: 'rgba(0, 0, 0, 1)',
            end: 'rgba(0, 0, 0, 1)'
         }
      }),
      gradient: false,
      color: () => ({
         top: 'rgba(0, 0, 0, 1)',
         bottom: 'rgba(0, 0, 0, 1)'
      })
   }
)

const emits = defineEmits<{
   progress: [number]
}>()

const refCanvas = ref<HTMLCanvasElement>()
const context = ref<CanvasRenderingContext2D | null>()

const percentValue = computed(() => {
   let rate = 0

   if (props.lineWidth >= 4) {
      rate = props.lineWidth / 2 - 1.5
   }

   const margin = (props.margin + rate) / 100
   const percentage = props.percentage / 100

   return {
      top: percentage - margin,
      bottom: 1 - percentage - margin
   }
})

function setColor(position: 'top' | 'bottom') {
   if (!refCanvas.value || !context.value) {
      return
   }

   if (props.gradient) {
      const gradient = context.value.createLinearGradient(
         props.gradientPosition[position].x1,
         props.gradientPosition[position].y1,
         props.gradientPosition[position].x2,
         props.gradientPosition[position].y2
      )
      gradient.addColorStop(0, props.gradientColor[position].start)
      if (props.gradientColor[position].center) {
         gradient.addColorStop(0.5, props.gradientColor[position].center)
      }
      gradient.addColorStop(1, props.gradientColor[position].end)
      context.value.strokeStyle = gradient
   } else {
      context.value.strokeStyle = props.color[position]
   }
}

function degreesToRadians(degrees: number): number {
   return degrees * (Math.PI / 180)
}

function clearCanvas() {
   if (!refCanvas.value || !context.value) {
      return
   }

   context.value.clearRect(0, 0, refCanvas.value.width, refCanvas.value.height)
}

function drawCircle(start: number, end: number) {
   if (!refCanvas.value || !context.value) {
      return
   }

   context.value.beginPath()
   context.value.arc(props.size / 2, props.size / 2, props.size / 2 - props.lineWidth, start, end)
   context.value.stroke()
}

function start() {
   if (!refCanvas.value) {
      throw new Error('Canvas element not found')
   }

   context.value = refCanvas.value.getContext('2d')
   if (!context.value) {
      throw new Error('2d context not supported')
   }

   refCanvas.value.width = props.size
   refCanvas.value.height = props.size
   context.value.lineWidth = props.lineWidth
   context.value.lineCap = 'round'

   let startTime: number

   function drawArc(timestamp: number) {
      if (!refCanvas.value || !context.value) {
         return
      }

      if (!startTime) startTime = timestamp
      const elapsed = timestamp - startTime

      const progress = Math.min(elapsed / (props.drawTime * 1000), 1)

      // let currentNumber = 0
      // currentNumber = Math.round(progress * 10000)
      // console.log(currentNumber)
      emits('progress', progress)

      const angleChange = progress * percentValue.value.top * 2 * Math.PI
      const startAngle = degreesToRadians(-90) - angleChange / 2
      const endAngle = degreesToRadians(-90) + angleChange / 2

      const angleChange2 = progress * percentValue.value.bottom * 2 * Math.PI
      const startAngle2 = degreesToRadians(90) - angleChange2 / 2
      const endAngle2 = degreesToRadians(90) + angleChange2 / 2

      clearCanvas()

      setColor('top')
      drawCircle(startAngle, endAngle)

      setColor('bottom')
      drawCircle(startAngle2, endAngle2)

      if (progress < 1) {
         requestAnimationFrame(drawArc)
      }
   }

   requestAnimationFrame(drawArc)
}

onMounted(() => {
   start()
})
</script>

<template>
   <canvas ref="refCanvas"></canvas>
</template>

<style scoped></style>
