import { h, type Component } from 'vue'
import { shallowRef, defineAsyncComponent } from 'vue'
import AuthModal from './components/AuthModal/AuthModal.vue'

export const modalComponent = shallowRef<Component>()

const Promocode = defineAsyncComponent(() => import('./components/PromocodeModal.vue'))
const LegalInfo = defineAsyncComponent(() => import('./components/LegalInfoModal.vue'))
const InfoCompany = defineAsyncComponent(() => import('./components/InfoCompanyModal.vue'))
const Onboarding = defineAsyncComponent(() => import('./components/Onboarding/OnboardingModal.vue'))

export const modals = {
   Promocode: () => {
      modalComponent.value = Promocode
   },
   LegalInfo: () => {
      modalComponent.value = LegalInfo
   },
   InfoCompany: () => {
      modalComponent.value = InfoCompany
   },
   Auth: (signIn?: boolean) => {
      modalComponent.value = {
         render() {
            return h(AuthModal, { signIn })
         }
      }
   },
   Onboarding: () => {
      modalComponent.value = Onboarding
   },
   clear: () => {
      modalComponent.value = undefined
   }
}
