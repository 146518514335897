<script setup lang="ts">
import { ref, onMounted, onUnmounted, computed } from 'vue'
import MapCardMatch from './MapCardMatch.vue'

const props = defineProps<{
   mapCount: number
   matchEnded: boolean
   maps: {
      name: string
      mapIsLive: boolean
      score?: number[] | null
      chance?: number[] | null
      teamLeftIsT?: boolean
   }[]
}>()

const mapsContainer = ref<HTMLDivElement>()

const lineTop = ref(false)
const lineBottom = ref(false)

const mapIsLive = computed(() => {
   return props.maps.some((map) => map.mapIsLive)
})

function calculateLines(target: HTMLDivElement) {
   const childHeight = (target.firstElementChild?.clientHeight || 0) - 1

   const isTop = target.scrollTop > childHeight
   const isBottom = target.scrollTop + target.clientHeight < target.scrollHeight - childHeight

   lineTop.value = isTop
   lineBottom.value = isBottom
}

function handleScroll(event: Event) {
   const target = event.target as HTMLDivElement
   calculateLines(target)
}

onMounted(() => {
   if (mapsContainer.value && mapsContainer.value.scrollHeight > mapsContainer.value.clientHeight) {
      calculateLines(mapsContainer.value)
      mapsContainer.value.addEventListener('scroll', handleScroll, { passive: true })
   }
})

onUnmounted(() => {
   if (mapsContainer.value) {
      mapsContainer.value.removeEventListener('scroll', handleScroll)
   }
})

// TODO: в момент прокрутки top и bottom линии не отображать
</script>

<template>
   <section class="relative tablet:h-96px">
      <div v-if="lineTop" aria-hidden="true" class="absolute top-0 left-8px right-8px h-3px bg-black-800 rounded-full"></div>
      <div
         ref="mapsContainer"
         class="max-h-96px border rounded-8px py-7px px-7px flex flex-col gap-y-4px scroll-y no-scrollbar"
         :class="mapCount === 1 && mapIsLive ? 'border-transparent' : 'border-gray-50/8 '"
      >
         <MapCardMatch
            v-for="map in maps"
            :key="map.name"
            :name="map.name"
            :map-is-live="map.mapIsLive"
            :match-ended="matchEnded"
            :score="map.score"
            :team-left-is-t="map.teamLeftIsT"
            :chance="map.chance"
         />
      </div>
      <div v-if="lineBottom" aria-hidden="true" class="absolute bottom-0 left-8px right-8px h-3px bg-black-800 rounded-full"></div>
   </section>
</template>

<style scoped></style>
