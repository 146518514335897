<script setup lang="ts">
import { computed } from 'vue'
import Header from './HeaderCardMatch.vue'
import Teams from './TeamsCardMatch.vue'
import Risk from './RiskCardMatch.vue'
import Maps from './MapsCardMatch.vue'
import Comment from './CommentCardMatch.vue'
import Footer from './FooterCardMatch.vue'

const props = defineProps<{
   matchId: number
   hasSubscription: boolean
   isPromo: boolean
   eventName: string
   eventLogo: string
   matchDate: Date | string
   matchIsLive: boolean
   matchEnded: boolean
   team1: {
      id: number
      name: string
      logo?: string | null
      logoX1?: string | null
      logoX2?: string | null
   }
   team2: {
      id: number
      name: string
      logo?: string | null
      logoX1?: string | null
      logoX2?: string | null
   }
   chance?: number[] | null
   odds?: number[] | null
   oddsLive?: number[] | null
   score?: number[] | null
   finalChance: boolean
   hasPredict: boolean
   winnerId?: number | null
   levelRisk?: number
   maps: {
      name: string
      mapIsLive: boolean
      score?: number[] | null
      chance?: number[] | null
      teamLeftIsT?: boolean
   }[]
   comment?: string
   author?: string
}>()

const leftChanceIsHigher = computed(() => {
   if (!props.chance) {
      return
   }

   if (props.chance[0] === props.chance[1]) {
      if (!props.odds) {
         return
      }

      return props.chance[0] > props.chance[1]
   }

   return props.chance[0] > props.chance[1]
})

const leftIsWinner = computed(() => {
   if (!props.winnerId) {
      return
   }

   return props.team1.id === props.winnerId
})

const predictionData = computed(() => {
   if (leftChanceIsHigher.value === undefined) {
      return {
         teamName: undefined,
         chance: undefined,
         odd: undefined
      }
   }

   if (leftChanceIsHigher.value) {
      return {
         teamName: props.team1.name,
         chance: props.chance?.[0],
         odd: props.odds?.[0]
      }
   }

   return {
      teamName: props.team2.name,
      chance: props.chance?.[1],
      odd: props.odds?.[1]
   }
})

const predictionIsCorrect = computed(() => {
   if (leftChanceIsHigher.value === undefined) {
      return
   }

   if (leftIsWinner.value === undefined) {
      return
   }

   return (leftChanceIsHigher.value && leftIsWinner.value) || (!leftChanceIsHigher.value && !leftIsWinner.value)
})

const mapCount = computed(() => props.maps.length)
</script>

<template>
   <article
      class="pt-4px pb-8px px-4px rounded-10px bg-gradient-to-b from-70% from-black-850 to-[#262428] flex flex-col gap-y-4px relative z-1"
      :class="{ 'border-gradient': isPromo }"
   >
      <Header :event-name="eventName" :event-logo="eventLogo" :match-date="matchDate" :match-is-live="matchIsLive" />
      <Teams
         :has-subscription="hasSubscription"
         :team1="team1"
         :team2="team2"
         :chance="chance"
         :left-chance-is-higher="leftChanceIsHigher"
         :odds="matchIsLive ? oddsLive ?? odds : odds"
         :score="score"
         :final-chance="finalChance"
         :has-predict="hasPredict"
         :has-winner="matchEnded && Boolean(winnerId)"
         :left-is-winner="leftIsWinner"
         :is-promo="isPromo"
      />
      <Risk
         :has-subscription="hasSubscription"
         :match-ended="matchEnded"
         :match-is-live="matchIsLive"
         :is-promo="isPromo"
         :has-predict="hasPredict"
         :level="levelRisk"
         :team-name="predictionData.teamName"
         :chance="predictionData.chance"
         :odd="predictionData.odd"
      />
      <Maps v-if="mapCount > 0" :maps="maps" :map-count="mapCount" :match-ended="matchEnded" />
      <Footer :has-subscription="hasSubscription" :match-id="matchId" />
      <Comment
         :has-subscription="hasSubscription"
         :match-ended="matchEnded"
         :comment="comment"
         :author="author"
         :prediction-is-correct="predictionIsCorrect"
         :team-name="predictionData.teamName"
         :is-promo="isPromo"
      />

      <template v-if="isPromo">
         <div
            aria-hidden="true"
            class="absolute z-3 -top-10px left-1/2 -translate-x-1/2 py-4px px-16px rounded-full s-semibold gradient-lava-700"
         >
            {{ $t('dashboard.matchCard.promo') }}
         </div>

         <div class="absolute inset-0 -z-10 pointer-events-none promo-gradient"></div>
      </template>
   </article>
</template>

<style scoped>
.border-gradient::after,
.border-gradient::before {
   content: '';
   position: absolute;
   inset: 0;
   border-radius: inherit;
   padding: 2px;
   z-index: 2;
   -webkit-mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
   -webkit-mask-composite: xor;
   mask-composite: exclude;
   pointer-events: none;
}
.border-gradient::after {
   --uno: 'bg-gradient-to-b from-orange-900 to-brown-600/0 to-50%';
}
.border-gradient::before {
   --uno: 'bg-gradient-to-b from-brown-600/0 to-brown-600/20';
}
.promo-gradient {
   background: radial-gradient(45% 15% at 50% 0%, rgba(222, 58, 30, 0.3) 0%, rgba(29, 30, 36, 0) 100%);
}
</style>
