<script setup lang="ts">
import ModalWrapper from './ModalWrapper.vue'

defineProps<{
   escOff?: boolean
   iconOff?: boolean
   stopCloseOutside?: boolean
   className?: string
}>()

const emit = defineEmits<{
   unlock: []
}>()

const modelValue = defineModel<boolean>({ required: true, default: false })

function unlock() {
   document.body.style.paddingRight = '0px'
   document.body.classList.remove('lock')
   emit('unlock')
}
</script>

<template>
   <Teleport to="body">
      <Transition appear name="modal-wrapper" @after-leave="unlock">
         <ModalWrapper
            v-if="modelValue"
            v-slot="{ setInvisible }"
            :esc-off="escOff"
            :icon-off="iconOff"
            :stop-close-outside="stopCloseOutside"
            :class-name="className"
            @close="modelValue = false"
         >
            <slot :set-invisible="setInvisible" />
         </ModalWrapper>
      </Transition>
   </Teleport>
</template>

<style scoped>
.modal-wrapper-enter-active,
.modal-wrapper-leave-active {
   transition: opacity 0.3s ease;
}

.modal-wrapper-enter-from,
.modal-wrapper-leave-to {
   opacity: 0;
}
</style>

<style>
.lock {
   overflow: hidden;
}
</style>
