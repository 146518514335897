<script setup lang="ts">
import { ref, computed } from 'vue'
import { useQuery } from '@vue/apollo-composable'
import { getFutureAndLiveMatches } from '@/gql/requests'
import MatchesHeader from '@/components/Match/MatchesHeader.vue'
import CardMatch from '@/components/CardMatch/CardMatch.vue'
import { useUserStore } from '@/stores/user'
import { matchesMapsScores } from '@/composables/socket-io'
import { locale } from '@/i18n'

const userStore = useUserStore()

const activeHeader = ref(true)

const { result } = useQuery(getFutureAndLiveMatches, { locale: locale.value })

const matches = computed(() => {
   const filteredMatches =
      result.value?.matches
         .map((match) => {
            const newOdds = [match.odds?.matchOutcome?.team1 || 0, match.odds?.matchOutcome?.team2 || 0]
            const newOddsLive = [match.oddsLive?.matchOutcome?.team1 || 0, match.oddsLive?.matchOutcome?.team2 || 0]
            const maps = match.maps.map((map) => {
               const socketMap = matchesMapsScores.value.get(match.id)?.get(map.map)

               return {
                  name: map.map,
                  chance: map.chance,
                  score: socketMap?.scores ? [socketMap?.scores[match.team1.id], socketMap?.scores[match.team2.id]] : undefined,
                  mapIsLive: socketMap?.mapOver === undefined ? false : !socketMap?.mapOver,
                  teamLeftIsT: socketMap?.currentTId === match.team1.id
               }
            })
            const team1 = {
               id: match.team1.id,
               name: match.team1.name,
               logo: match.team1.logo,
               logoX1: match.team1.logo_x1,
               logoX2: match.team1.logo_x2
            }
            const team2 = {
               id: match.team2.id,
               name: match.team2.name,
               logo: match.team2.logo,
               logoX1: match.team2.logo_x1,
               logoX2: match.team2.logo_x2
            }

            return { ...match, newOdds, newOddsLive, maps, team1, team2 }
         })
         .filter((match) => match.status === 'LIVE') ?? []

   return filteredMatches
})
</script>

<template>
   <section v-if="matches.length > 0" class="grid gap-y-20px <laptop:px-16px">
      <MatchesHeader v-model="activeHeader" :count="matches.length" :button-filter="false">{{ $t('matches.live.title') }}</MatchesHeader>
      <div v-if="activeHeader" class="grid grid-cols-3 items-start <tablethd:grid-cols-2 <tablet:grid-cols-1 gap-20px <fullhd:gap-16px">
         <CardMatch
            v-for="match in matches"
            :key="match.id"
            :has-subscription="userStore.userSub"
            :match-id="match.id"
            :is-promo="match.isPromo"
            :event-name="match.event.name"
            :event-logo="match.event.logo ?? '#'"
            :match-date="match.date"
            :match-is-live="true"
            :match-ended="false"
            :team1="match.team1"
            :team2="match.team2"
            :chance="match.chance"
            :odds="match.newOdds"
            :odds-live="match.newOddsLive"
            :score="match.score"
            :final-chance="match.finalChance"
            :has-predict="match.hasPredict"
            :winner-id="match.winnerId"
            :level-risk="match.levelRisk ?? undefined"
            :maps="match.maps"
            :comment="match.comment?.all ?? undefined"
            :author="match.commentAuthor ?? undefined"
         />
      </div>
   </section>
</template>

<style scoped></style>
