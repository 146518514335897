<script setup lang="ts">
import { computed } from 'vue'
import dayjs from 'dayjs'
import { locale } from '@/i18n'

const props = defineProps<{
   eventName: string
   eventLogo: string
   matchDate: Date | string
   matchIsLive: boolean
}>()

const formattedDate = computed(() => {
   const date = dayjs(props.matchDate).locale(locale.value)
   return {
      day: date.format('DD.MM'),
      time: date.format('HH:mm'),
      iso: date.toISOString()
   }
})
</script>

<template>
   <section class="px-8px flex items-center justify-between gap-x-12px">
      <div class="flex items-center gap-x-6px s-medium overflow-hidden">
         <img :src="eventLogo" alt="event logo" class="w-16px h-16px" />
         <span class="truncate">{{ eventName }}</span>
      </div>
      <div class="flex items-center gap-x-4px min-h-40px">
         <div
            v-if="matchIsLive"
            class="py-3px px-11px flex items-center gap-x-4px s-semibold text-red-700 border border-red-700/30 rounded-full"
         >
            <div class="w-4px h-4px rounded-full bg-red-700"></div>
            Live
         </div>
         <time
            v-else
            :datetime="formattedDate.iso"
            class="min-w-92px py-3px flexc gap-x-6px s-semibold border border-brown-700 rounded-full"
         >
            <span>{{ formattedDate.day }}</span>
            <div class="w-1px h-12px bg-brown-700 rounded-full"></div>
            <span>{{ formattedDate.time }}</span>
         </time>

         <!-- <div class="p-10px"><i class="i-custom-info-outline text-20px block"></i></div> -->
      </div>
   </section>
</template>
