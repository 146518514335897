<script setup lang="ts">
import { ref, watchEffect, nextTick, useTemplateRef, onMounted } from 'vue'
import { useDebounceFn } from '@vueuse/core'

type TModelValue = string | number | undefined

type PropsBasic = {
   debounce?: number
   maxCharacters?: number
   minNumber?: number
   maxNumber?: number
   inputType?: string
   maxLength?: number
   max?: number
   selectedFocus?: boolean
}

type PropsTypeText = {
   modelValue?: string
   type?: 'text'
}

type PropsTypeNumber = {
   modelValue?: number
   type?: 'number'
}

type Props = PropsBasic & (PropsTypeText | PropsTypeNumber)

const refInput = useTemplateRef('input')

const props = withDefaults(defineProps<Props>(), {
   inputType: 'text',
   type: 'text',
   debounce: 0,
   max: undefined,
   minNumber: undefined,
   maxNumber: undefined
})

const emit = defineEmits<{
   (event: 'update:modelValue', value: TModelValue): void
}>()

const inputValue = ref<TModelValue>()
watchEffect(() => {
   if (props.type === 'number' && Number(inputValue.value) === props.modelValue) {
      return
   }
   inputValue.value = props.modelValue
})

const sendEmit = useDebounceFn((value: string) => {
   inputValue.value = value

   let newValue: TModelValue = value.length > 0 ? value : undefined
   newValue = newValue && props.type === 'number' ? Number(value) : newValue
   emit('update:modelValue', newValue)
}, props.debounce)

const handler = async (event: Event) => {
   const element = event.target as HTMLInputElement
   await nextTick()
   Object.keys(element.dataset).forEach((key) => {
      delete element.dataset[key]
   })

   if (event instanceof InputEvent && event.inputType === 'insertFromPaste') {
      if (props.type === 'number') {
         element.value = element.value.replace(/[,бю]/, '.')

         if (Number.isNaN(Number(element.value))) {
            element.value = ''
            return
         }
      }
      if (props.max && element.value.length > props.max) {
         element.value = element.value.slice(0, props.max)
      }
   }

   if (props.max && element.value.length > props.max) {
      element.value = element.value.slice(0, -1)
   }

   if (props.type === 'number') {
      element.value = element.value.replace(/[,бю]/, '.')

      if (!/^\d+(\.?)\d*$/g.test(element.value)) {
         element.value = element.value.slice(0, -1)
      }

      if (props.maxNumber && Number(element.value) > props.maxNumber) {
         element.value = props.maxNumber.toString()
      }

      if (props.minNumber && Number(element.value) < props.minNumber && Number(element.value) !== 0) {
         element.value = props.minNumber.toString()
      }
   }

   if (props.maxLength && element.value.length > props.maxLength) {
      element.value = element.value.slice(0, props.maxLength)
   }

   sendEmit(element.value)
}

onMounted(() => {
   if (props.selectedFocus) {
      refInput.value?.select()
   }
})
</script>

<template>
   <input
      ref="input"
      :value="inputValue"
      :type="inputType"
      class="outline-none bg-transparent w-full h-full"
      :inputmode="type === 'number' ? 'decimal' : 'text'"
      autocomplete="off"
      @input="handler"
   />
</template>
