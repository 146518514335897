<script setup lang="ts">
import { ref, watch, computed } from 'vue'
import { useUserStore } from '@/stores'
import { useQuery } from '@vue/apollo-composable'
import { getBestMatches, getFutureAndLiveMatches } from '@/gql/requests'
import MatchesHeader from '@/components/Match/MatchesHeader.vue'
import CardMatch from '@/components/CardMatch/CardMatch.vue'
import { locale } from '@/i18n'
const userStore = useUserStore()

const { result: futureAndLiveMatches, loading: loadingFuture } = useQuery(
   getFutureAndLiveMatches,
   { locale: locale.value },
   {
      fetchPolicy: 'cache-only'
   }
)

const activeHeader = ref(true)

const { result } = useQuery(getBestMatches)

const matches = computed(() => {
   const filteredMatches =
      result.value?.bestMatches.map((match) => {
         const newOdds =
            match.odds?.matchOutcome?.team1 && match.odds?.matchOutcome?.team2
               ? [match.odds.matchOutcome.team1, match.odds.matchOutcome.team2]
               : undefined
         const newOddsLive = undefined

         const team1 = {
            id: match.team1.id,
            name: match.team1.name,
            logo: match.team1.logo,
            logoX1: match.team1.logo_x1,
            logoX2: match.team1.logo_x2
         }
         const team2 = {
            id: match.team2.id,
            name: match.team2.name,
            logo: match.team2.logo,
            logoX1: match.team2.logo_x1,
            logoX2: match.team2.logo_x2
         }

         const maps = match.maps.map((map) => {
            return {
               name: map.map,
               chance: map.chance,
               score: map.score,
               mapIsLive: false
            }
         })

         return { ...match, newOdds, newOddsLive, maps, team1, team2 }
      }) ?? []

   return filteredMatches
})

watch(
   () => userStore.loading && loadingFuture.value,

   (nv) => {
      if (!nv && userStore.userSub && futureAndLiveMatches.value?.matches.length) {
         activeHeader.value = false
      }
   },
   { immediate: true }
)
</script>

<template>
   <section class="grid gap-y-20px">
      <MatchesHeader v-model="activeHeader" :button-filter="false">{{ $t('dashboard.bestMatches.title') }}</MatchesHeader>
      <div v-if="activeHeader" class="grid grid-cols-3 items-start <tablethd:grid-cols-2 <tablet:grid-cols-1 gap-20px <laptop:gap-16px">
         <CardMatch
            v-for="match in matches"
            :key="match.id"
            :has-subscription="userStore.userSub"
            :match-id="match.id"
            :is-promo="false"
            :event-name="match.event.name"
            :event-logo="match.event.logo ?? '#'"
            :match-date="match.date"
            :match-is-live="false"
            :match-ended="true"
            :team1="match.team1"
            :team2="match.team2"
            :chance="match.chance"
            :odds="match.newOdds"
            :odds-live="match.newOddsLive"
            :score="match.score"
            :final-chance="match.finalChance"
            :has-predict="match.hasPredict"
            :winner-id="match.winnerId"
            :level-risk="match.levelRisk ?? undefined"
            :maps="match.maps"
            :author="match.commentAuthor ?? undefined"
         />
      </div>
   </section>
</template>

<style scoped></style>
