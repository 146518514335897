<script setup lang="ts">
import { useQuery } from '@vue/apollo-composable'
import { getFutureAndLiveMatches } from '@/gql/requests'
import { useUserStore } from '@/stores'
import BannerLuna from './BannerLuna.vue'
import SuccessTracker from '@/components/SuccessTracker/SuccessTracker.vue'
import MatchesLive from '@/components/Matches/MatchesLive.vue'
import MatchesFuture from '@/components/Matches/MatchesFuture.vue'
import MatchesBest from '@/components/Matches/MatchesBest.vue'
import Reviews from '@/components/Reviews.vue'
import WhatYouGet from './WhatYouGet/WhatYouGet.vue'
import WhereBegin2 from './WhereBegin2.vue'
import BannerNoMatch from '@/components/BannerNoMatch.vue'
import { locale } from '@/i18n'
const userStore = useUserStore()

const { result } = useQuery(getFutureAndLiveMatches, { locale: locale.value })
</script>

<template>
   <div class="grid gap-y-48px <laptop:gap-y-32px">
      <BannerLuna v-if="userStore.userUnReg" class="<laptop:mx-16px" />
      <SuccessTracker />
      <WhatYouGet v-if="userStore.userUnReg" class="<laptop:mx-16px" />
      <WhereBegin2 v-if="userStore.user && !userStore.user.onboarding.collapsed" />
      <BannerNoMatch v-if="result?.matches.length === 0" :small="false" class="<laptop:mx-16px" />
      <MatchesLive />
      <MatchesFuture />
      <MatchesBest class="<laptop:px-16px" />
      <Reviews v-if="userStore.userUnReg" :column="4" />
   </div>
</template>
